.user-billing-data-menu-popup-dialog-container{
    padding: 20px
}
.user-billing-data-menu-main-container{
}
.user-billing-data-menu-content-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 20px;
}
.user-billing-data-menu-main-title{
    padding-bottom: 20px;
}
.user-billing-data-menu-currency-group-container{
    display: flex;
    justify-content: end;
    column-gap: 10px;
    box-sizing: border-box;
}
.user-billing-data-payment-method-group-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.user-billing-data-payment-method-field-value-button-container{
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    align-items: center;
    justify-content: end;
    column-gap: 10px;
}
.user-billing-data-menu-field-value{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
.user-billing-data-menu-group-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 10px;
}
.user-billing-data-menu-inline-field-group-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 10px;
    align-items: center;
}
.user-billing-data-menu-field-value-button-container{
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    align-items: center;
    justify-content: end;
    column-gap: 10px;
}
@media screen and (min-width:1120px) {
    
}
@media screen and (max-width:1119px) {
    .user-billing-data-payment-method-group-container{
        flex-direction: column;
        align-items: normal;
    }
    .user-billing-data-payment-method-field-value-container{
        column-gap: 8px;
    }
    .user-billing-data-menu-inline-field-group-container{
        flex-direction: column;
        align-items: normal;
    }
    .user-billing-data-menu-field-value-button-container{
        align-items: end;
    }
    .user-billing-data-menu-field-value{
        flex-grow: 1;
        white-space: pre-line;
    }
}
