/* @import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap'); */
.professor-hero-main-container{
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.professor-hero-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media screen and (min-width:1120px) {
    .professor-hero-main-container{
        position:relative;
        padding: 72px 0px;
    }
    .professor-hero-main-container-no-lead-paper{
        display: flex;
        row-gap: 32px;
        box-sizing: border-box;
        justify-content: center;
        width: 90%;
        padding: 32px;
        column-gap: 32px;
        border-radius: 10px;
    }
    .professor-hero-info-container{
        flex-basis: calc(50% - 32px);
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .professor-hero-info-container.no-lead{
        flex-grow: 1;
    }
    .professor-hero-img-container.lead{
        position:absolute;
        bottom: 0px;
        right:0px;
        width: calc(50% - 32px);
        height:100%;
        border-radius: 5px;
        overflow: hidden;
        max-height: 500px;
    }
    .professor-hero-img.lead{
        object-fit: contain;
        object-position: bottom;
    }
    .professor-hero-img-container.no-lead{
        /* display:flex; */
        /* box-sizing: border-box; */
        height: 100%;
    }
    .professor-hero-img.no-lead{
        height: 200px;
        width: 200px;
        object-fit: cover;
        border-radius: 50%;
    }
}

@media screen and (min-width:681px) and (max-width:1119px) {
    .professor-hero-main-container{
        row-gap: 32px;
        flex-direction: column;
    }
    .professor-hero-main-container-no-lead-paper{
        display: flex;
        row-gap: 32px;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        width: 80%;
        padding: 32px;
        border-radius: 10px;
    }
    .professor-hero-info-container{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .professor-hero-img-container.lead{
        height: 100%;
    }
    .professor-hero-img.lead{
        height: 300px;
        width: 300px;
        object-fit: cover;
        border-radius: 50%;
    }
    .professor-hero-img-container.no-lead{
        /* display:flex; */
        /* box-sizing: border-box; */
        height: 100%;
    }
    .professor-hero-img.no-lead{
        height: 200px;
        width: 200px;
        object-fit: cover;
        border-radius: 50%;
    }
}

@media screen and (max-width:680px) {
    .professor-hero-main-container{
        /* padding: 40px 0px; */
        row-gap: 32px;
        flex-direction: column;
    }
    .professor-hero-main-container-no-lead-paper{
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        box-sizing: border-box;
        align-items: center;
        width: 90%;
        padding: 16px;
        border-radius: 10px;
    }
    .professor-hero-info-container{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
    .professor-hero-img-container{
        height: 296px;
        width: 296px;
        overflow: hidden;
        border-radius: 50%;
    }
    .professor-hero-img{
        object-fit: cover;
        height: 100%;
    }
    .professor-hero-img-container.no-lead{
        /* display:flex; */
        /* box-sizing: border-box; */
        height: 200px;
        width: 200px;
    }
}
.professor-hero-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}