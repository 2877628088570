.content-consumption-options-wrapper{
    display:flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 72px 0px
}
.content-consumption-options-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 32px;
    align-items: center;
    width: 100%;
}
.content-consumption-options-options-container{
    display:flex;
    box-sizing: border-box;
    column-gap: 32px;
    justify-content: center;
    width: 100%;
}
.content-consumption-options-option-container{
    flex-basis: 380px;
}
.content-consumption-options-option-no-included-warning-container{
    padding-bottom: 16px;
}
.content-consumption-options-option-no-subscriptable-warningcontainer{
    padding-bottom: 16px;
}
.content-consumption-options-option-description-and-perks-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 16px;
    width: 100%;
}
.content-consumption-options-option-description-container{
    width: 100%;
}
.content-consumption-options-option-perks-container{
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 8px;
    padding: 0px 8px;
}
.content-consumption-options-option-perk-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 8px;
    width: 100%;
}
.content-consumption-options-option-perk-icon{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-shrink: 0;
}
@media screen and (max-width: 680px) {
    .content-consumption-options-wrapper{
        /* padding: 48px 0px */
        padding: 72px 0px
    }
    .content-consumption-options-options-container{
        flex-direction: column;
        row-gap: 32px;
    }
}