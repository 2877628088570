.checkout-item-quantity-pop-up-title-main-container{
    height: 80px;
    padding-left: 45px;
    align-items: center;
}
.checkout-item-quantity-pop-up-title-img-container{
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
}
.checkout-item-quantity-pop-up-title-img{
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.checkout-item-quantity-pop-up-title-skeleton{
    height: 40px !important;
    width: 40px;
    object-fit: cover;
}
.checkout-item-quantity-pop-up-title-description{
    height: 40px;
    padding-left: 10px;
}
.checkout-item-quantity-pop-up-warning-toggle{
    opacity: 0%;
    max-height: 0px;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
}
.checkout-item-quantity-pop-up-warning-toggle.open{
    opacity: 100%;
    max-height: 1000px;
}
.checkout-item-quantity-pop-up-set-container{
    height: 100px;
}
.checkout-item-quantity-pop-up-set-input{
    height: 40px;
    width: 50px;
}
.checkout-item-quantity-pop-up-set-button{
    align-items: center;
    padding: 0px 20px 20px 20px;
}
.checkout-item-quantity-pop-up-error-toogle {
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-item-quantity-pop-up-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}