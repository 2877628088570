.small-footer-wrapper{
    display: flex;
    justify-content: center;
}
.small-footer-main-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    max-width: 1440px;
    width: 100%;
}
.small-footer-brand-name-container{
    flex-grow: 1;
}
.small-footer-social-container{
    display: flex;
    box-sizing: border-box;
    flex-wrap: nowrap;    
    column-gap: 8px;
}
@media screen and (min-width:1120px){
    .small-footer-main-container{
        height: 60px;
        padding: 0px 40px;
        justify-content: space-between;
    }
}
@media screen and (max-width:1119px) and (min-width:681px){
    .small-footer-main-container{
        height: 60px;
        padding: 0px 32px;
        justify-content: space-between;
    }
}
@media screen and (max-width:680px) {
    .small-footer-main-container{
        padding: 32px 12px;
        justify-content: center;
        row-gap: 10px;
        flex-direction: column;
    }
    .small-footer-brand-name-container{
        order: 1
    }
}