.content-description-wrapper{
    display: flex;
    justify-content: center;
}
.content-description-main-container{
    display:flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
}
@media screen and (min-width: 1120px) {
    .content-description-main-container{
        max-width: 1440px;
        padding: 72px 160px;
        column-gap: 64px;
        flex-wrap: nowrap;
    }
    .content-description-description-container{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        box-sizing: border-box;
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-description-img-container{
        margin: auto 0px;
        /* flex-grow: 1; */
        flex-basis: 50%;
    }
    .content-description-img{
        width:100%;
        display: block;
        /* min-height: 536px; */
        height:fit-content;
        /* max-height: calc(100vh - 60px); */
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-description-main-container{
        padding: 72px 32px;
        row-gap: 16px
    }
    .content-description-title-container{
        width: 100%;
    }
    .content-description-description-img-container{
        display: flex;
        box-sizing: border-box;
        column-gap: 32px
    }
    .content-description-description-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-description-img-container{
        margin: auto 0px;
        flex-basis: 50%;
    }
    .content-description-img{
        width:100%; 
        margin: 0px auto;
        display: block;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    }
}
@media screen and (max-width: 680px) {
    .content-description-main-container{
        padding: 48px 12px;
        /* row-gap: 24px */
        row-gap: 32px
    }
    .content-description-title-container{
        width: 100%;
        text-align: center;
    }
    .content-description-description-img-container{
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        /* row-gap: 24px */
        row-gap: 32px
    }
    .content-description-description-container{
        flex-basis: 50%;
        order: 1;
    }
    .content-description-img-container{
        order: 0;
    }
    .content-description-img{
        width:80%;
        max-width: 340px;
        margin: 0px auto;
        display: block;
        object-fit: cover;
        border-radius: 5px;
        box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    }
}
.content-description-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}