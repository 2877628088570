.content-preview-wrapper{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.content-preview-main-container{
    box-sizing: border-box;
    width: 100%;
}
@media screen and (min-width:1120px) {
    .content-preview-main-container{
        max-width: 1440px;
        padding: 72px 160px; 
    }
}
@media screen and (max-width:1119px) and (min-width:681px) {
    .content-preview-main-container{
        padding: 72px 32px; 
    }
}
@media screen and (max-width:680px) {
    .content-preview-main-container{
        padding: 48px 12px; 
    }
}
.content-preview-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}