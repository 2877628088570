.classroom-side-column-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
.classroom-side-column-header-container{
    display: flex;
    align-items: end;
    box-sizing: border-box;
    width: 100%;
}
.classroom-side-column-bar-container{
    position:relative;
    box-sizing: border-box;
    margin: 0 auto;
}
.classroom-side-column-bar-item-wrapper{
    position: absolute;
    width:100%;
    height: 100%;
    opacity: 0;
    transition-delay: 0s;
    pointer-events: none;
    padding-left: 10px;
    transition: opacity ease-in-out 0.3s, padding-left ease-in-out 0.3s;
    overflow: hidden;
}
