.purchase-process-roadmap-main{

}
.purchase-process-roadmap-step-container{
    padding:  8px;
    box-sizing: border-box;
}

.purchase-process-roadmap-line{
    height: 1px;
}