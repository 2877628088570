.recommended-content-section-wrapper{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
}
.recommended-content-section-main-container{
    width: 100%;
    max-width: 1440px;
}
.recommended-content-section-carrousel-no-slider-inner-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
}
@media screen and (min-width:1120px ) {
    .recommended-content-section-carrousel-container{
        max-width: inherit;
        padding: 0px 80px;
    } 
    .recommended-content-section-main-container{
        padding: 72px 0px;
        max-width: 1440px;
    }
    .recommended-content-section-title{
        padding: 0 160px 64px 160px
    }
    .recommended-content-section-subtitle{
        padding: 0 160px 48px 160px
    }
    .recommended-content-section-carrousel-slider-inner-container{
        /* width: 1520px; */
        width: 1360px;
        padding: 0px 80px;
        box-sizing: border-box;
    }
    .recommended-content-section-carrousel-no-slider-inner-container{
        padding: 0px 80px;
        column-gap: 16px;
        flex-wrap: nowrap !important;
        /* allowing overflow if no slider is applied */
        /* overflow-x: hidden; */
    } 
    .recommended-content-section-carrousel-item{
        padding-bottom: 16px;
    }
}
@media screen and (min-width:681px) and (max-width: 1119px) {
    .recommended-content-section-main-container{
        padding: 72px 0px;
    }
    .recommended-content-section-title{
        padding: 0 32px 64px 32px
    }
    .recommended-content-section-subtitle{
        padding: 0 32px 48px 32px
    }
    .recommended-content-section-carrousel-container{
        max-width: inherit;
        padding: 0px 16px
    }
    .recommended-content-section-carrousel-slider-inner-container{
        width: 1248px;
        /* width: 1048px; */
        /* width: 936px; */
        padding: 0px 16px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 980px) {
        .recommended-content-section-carrousel-slider-inner-container{
            width: 936px;
        }
    }
    .recommended-content-section-carrousel-no-slider-inner-container{
        padding: 0px 16px; 
        column-gap: 16px;
        flex-wrap: nowrap !important;
        overflow-y: hidden;
    } 
    .recommended-content-section-carrousel-item{
        padding-bottom: 16px;
        padding-left: 4px;
        box-sizing: border-box;
    }
}
@media screen and (max-width:680px) {
    .recommended-content-section-carrousel-container{
        max-width: inherit;
        /* updated 25/05 */
        /* padding: 0px 6px */
    }
    .recommended-content-section-main-container{
        /* padding: 48px 0px; */
        padding: 72px 0px;
    }
    .recommended-content-section-title{
        /* padding-bottom: 40px; */
        padding: 0 12px 64px 12px
    }
    .recommended-content-section-subtitle{
        /* padding-bottom: 24px; */
        text-align: center !important;
        padding: 0 12px 48px 12px
    }
    .recommended-content-section-carrousel-slider-inner-container{
        width: 624px;
        padding: 0px 12px;
        box-sizing: border-box;
    }
    .recommended-content-section-carrousel-item{
        padding-bottom: 16px;
        padding-left: 16px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 340px) {
        .recommended-content-section-carrousel-container{
            padding: 0px
        }
        .recommended-content-section-carrousel-slider-inner-container{
            /* width: inherit; */
            width: 300px;
            padding: 0px;
        }
        .recommended-content-section-carrousel-item{
            padding: 0px 12px 24px 12px;
            /* width: inherit !important */
            width: 100%;
            box-sizing: border-box;
        }
    }
}
.recommended-content-section-main-container.no-vertical-padding{
    padding-top:0px;
    padding-bottom: 0px;
}