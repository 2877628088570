.landing-value-in-figures-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.landing-value-in-figures-main-container{
    width:100%;
}
@media screen and (min-width:1120px) {
    .landing-value-in-figures-main-container{
        padding-left: 160px;
        padding-right: 160px;
        padding-top: 72px;
        padding-bottom: 72px;
        max-width: 1440px;
    }
    .landing-value-in-figures-item{
        padding-left: 64px ;
        padding-right: 64px ;
    }
    .landing-value-in-figures-item-figure{
        padding-bottom: 8px ;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-value-in-figures-main-container{
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 72px;
        padding-bottom: 72px;
    }
    .landing-value-in-figures-item{
        padding-left: 32px ;
        padding-right: 32px ;
    }
    .landing-value-in-figures-item-figure{
        padding-bottom: 8px ;
    }
}
@media screen and (max-width:680px) {
    .landing-value-in-figures-main-container{
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 56px;
        padding-bottom: 56px;
        flex-direction: column;
    }
    .landing-value-in-figures-item{
        padding-left: 32px ;
        padding-right: 32px ;
        padding-bottom: 40px;
    }
    .landing-value-in-figures-item.last-item{
        padding-bottom: 0px;
    }
    .landing-value-in-figures-item-figure{
        padding-bottom: 8px ;
    }
}