.landing-faq-wrapper{
    display: flex;
    justify-content: center;
}
.landing-faq-item:not(:last-child){
    border-bottom: solid 1px
}
.landing-faq-item{
    width: 100%;
    /* Remove highlight when touching in webkit based browsers: Android/Safari mobile/Edge */
    -webkit-tap-highlight-color: transparent;
}
@media screen and (min-width:1120px) {
    .landing-faq-main-container{
        padding: 0px 160px;
        max-width: 1440px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-faq-main-container{
        padding: 0px 32px;
    }
}
@media screen and (max-width:680px) {
    .landing-faq-main-container{
        padding: 0px 12px;
    }
}