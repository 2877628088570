.meeting-description-main-container{
    width: 100%;
    box-sizing: border-box;
}
.meeting-description-feature-container{
    padding-top: 10px;
}
.meeting-description-feature{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding-left: 5px;
    column-gap: 5px;
    align-items: center;
}
.meeting-description-panelist{
    padding: 0px 2px
}
.meeting-description-panelist.first-panelist{
    padding: 0px 2px 0px 0px;
}
