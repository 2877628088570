.class-content-compatible-system-select-main{
}

.class-content-compatible-system-select-form{
    min-height: 150px;
}
.class-content-compatible-system-select-form-item-default{
    font-style: italic;
    display: inline;
}
.class-content-compatible-system-select-form-button{
    padding-top: 20px;
}