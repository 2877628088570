.featured-features-widget-main-container{
    display:flex;
    flex-wrap: wrap;
    width:100%;
    height: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}
.featured-features-widget-feature-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    flex-grow: 1;
}
.featured-features-widget-feature-label{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    column-gap: 4px;
}
@media screen and (max-width: 1119px) {
    .featured-features-widget-main-container{
        row-gap:24px;
    }
    .featured-features-widget-feature-container{
        flex-basis: 50%;
    }
}