.class-notes-wrapper{
    height: 100%;
    position: relative;
}
.class-notes-container{
    overflow-y: auto;
    height: 100%;
    padding: 10px 10px 0px 10px;
}
.class-notes-user-notes-container-wrapper{
    width: 100%
}
.class-notes-user-notes-container{
    height: 100%;
    margin-bottom: 50px ;
}
.class-notes-user-note{
    padding-bottom: 10px;
}
.class-notes-user-add-note-container{
    /* position: absolute;
    width: calc(100% - 20px);
    bottom: 15px;
    left: 10px */
}
.class-notes-animation-wrapper{
    height: 100%;
    overflow-y: hidden;
    max-width: 380px;
    margin: 0px auto;
}
.class-notes-modal-dialog{
    padding: 20px;
    text-align: center;
}