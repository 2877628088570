.video-thumbnail-preview-main-container{
    position:relative;
    width:100%;
    height: 100%;
    background-color: black;
    overflow: hidden;
}
.video-thumbnail-preview-loading{
    position: absolute;
    width: 100%;
    height: 100%;
}
.video-thumbnail-preview-img-container{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
    /* z-index: 1; */
    transition: opacity ease-in-out 0.3s;
}
.video-thumbnail-preview-img-container.visible{
    opacity: 100%;
}
.video-thumbnail-preview-img{
    width:100%;
    /* DEBUG */
    height: 100%;
    object-fit: contain;
}
.video-thumbnail-preview-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    /* opacity: 0; */
}
.video-thumbnail-preview-overlay-background{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
}
.video-thumbnail-preview-overlay-background.ready{
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}
.video-thumbnail-preview-overlay-background.ready.hover{
    opacity: 0.3;
}
.video-thumbnail-preview-icon-container{
    cursor: pointer;
    position:absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); /* move the element with respect to its own bounding box */ 
}

.video-thumbnail-preview-icon{
    font-size: 6em !important;
    opacity: 80%;
}

.video-thumbnail-preview-icon:hover{
    opacity: 100%;
}

@media screen and (max-width:680px) {
    .video-thumbnail-preview-overlayer{
        opacity: 100%;
    }
    .video-thumbnail-preview-overlayer-background{
        background-color: transparent;
    }
    .video-thumbnail-preview-icon{
        opacity: 100%;
    }
    
}
