.content-workguide-vs-protocol-main-container{
    display: flex;
    flex-direction: column;
    row-gap: 56px;
}
@media screen and (min-width: 1120px) {
    .content-workguide-vs-protocol-main-container{
        padding: 72px 0px;
        row-gap: 56px;
    } 
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-workguide-vs-protocol-main-container{
        padding: 72px 0px;
        row-gap: 56px;
    } 
}
@media screen and (max-width: 680px) {
    .content-workguide-vs-protocol-main-container{
        /* padding: 48px 0px;
        row-gap: 40px; */
        padding: 72px 0px;
        row-gap: 56px;
    } 
}
.content-workguide-vs-protocol-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}