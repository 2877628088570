.landing-featured-content-wrapper{
    display: flex;
    justify-content: center;
}
.landing-featured-content-title{
    text-align: center;
}
.landing-featured-content-slider-inner-container{
    height: 100%;
    box-sizing: border-box;
}
.landing-featured-content-item{
    width: 100%;
}
@media screen and (min-width:1120px) {
    .landing-featured-content-main-container{
        max-width: 1440px;
        padding: 72px 40px; 
    }
    .landing-featured-content-title{
        padding-bottom: 64px;
    }
}
@media screen and (max-width:1119px) and (min-width:681px) {
    .landing-featured-content-main-container{
        padding: 72px 32px; 
    }
    .landing-featured-content-title{
        padding-bottom: 64px;
    }
}
@media screen and (max-width:680px) {
    .landing-featured-content-main-container{
        padding: 56px 12px; 
    }
    .landing-featured-content-title{
        padding-bottom: 40px;
    }
}