.refund-wrapper{
    /* header */
    padding-top: 60px ;
}
.refund-main-container{
    min-height: calc(100vh - 60px);
    min-height: calc(100dvh - 60px);
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.refund-title-container{
    text-align: center;
}
.refund-content-date{
    color: rgba(0, 0, 0, 0.4);
}
.refund-markdown-wrapper{
}
.refund-alert-msg{
    white-space: pre-line;
}
.refund-one-click-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 40px;
}
.refund-step-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    column-gap: 40px;
}
.refund-step-img-container{
    border-radius: 5px;
    margin: 0 auto;
    overflow: hidden;
}
.refund-step-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
@media screen and (min-width:1120px) {
    /* .refund-content-wrapper{
        max-width: 1440px;
        padding: 160px 0px;
    } */
    .refund-main-container{
        padding: 100px 160px;
        box-sizing: border-box;
    }
    .refund-step-container{
        align-items: center
    }
    .refund-step-description-container{
        flex-grow: 1;
    }
    .refund-step-img-container{
        min-width: 400px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px){
    /* .refund-content-wrapper{
        padding: 160px 0px
    } */
    .refund-main-container{
        padding: 100px 32px;
        box-sizing: border-box;
    }
    .refund-step-container{
        flex-direction: column;
        row-gap: 40px;
    }
}
@media screen and (max-width: 680px){
    /* .refund-content-wrapper{
        padding: 80px 0px;
 
    } */
    .refund-main-container{
        padding: 20px 12px 80px 12px;
        box-sizing: border-box;
    }
    .refund-step-container{
        flex-direction: column;
        row-gap: 20px;
    }
}