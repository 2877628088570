.meeting-panelist-badge-main-wrapper{
    /* height: 40px; */
    /* width:  40px; */
    position: relative;
}

.meeting-panelist-badge-status{
    border-radius: 50%;
    position: absolute;
    right: 0px;
    bottom: 0px;
}