.cookie-consent-custom-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;
    max-height: 0px;
    transition: max-height ease-in-out 300ms
}
.cookie-consent-custom-wrapper.show{
    max-height: 1000px;
    opacity: 1;
}
.cookie-consent-custom-main-container{
    width: 100%;
    bottom: 0px;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 20px; */
    max-width: 1440px;
    min-height: 60px;
}
.cookie-consent-custom-decline-button{
    margin: 0px 20px !important;
}
.cookie-consent-custom-content-container{
    margin: 10px 0px
}
@media screen and (min-width: 1120px){
    .cookie-consent-custom-main-container{
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px){
    .cookie-consent-custom-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (max-width: 680px){
    .cookie-consent-custom-main-container{
        padding-left: 12px;
        padding-right: 12px;
    }
    .cookie-consent-custom-content-container{
        flex-grow: 1;
        flex-basis: 0;
    }
    .cookie-consent-custom-button{
        margin-left: 20px !important;
    }
}