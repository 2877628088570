
.workguide-better-than-a-protocol-wrapper{
    display: flex;
    justify-content: center;
}
.workguide-better-than-a-protocol-main-container{
    box-sizing: border-box;
    width:100%;
    max-width:1440px
}
.workguide-better-than-a-protocol-content-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}
.workguide-better-than-a-protocol-content-description-container{
    white-space: pre-wrap;
}
.workguide-better-than-a-protocol-content-img{
    display: block;
    height: fit-content;
    object-fit: contain;
}

@media screen and (min-width:1120px) {
    .workguide-better-than-a-protocol-main-container{
        padding: 72px 160px;
    }
    .workguide-better-than-a-protocol-title-container{
        padding-bottom: 64px;
    }
    .workguide-better-than-a-protocol-content-container{
        column-gap: 32px;
    }
    .workguide-better-than-a-protocol-header{
        padding-bottom: 16px;
    }
    .workguide-better-than-a-protocol-content-description-container{
        flex-basis: 50%;
    }
    .workguide-better-than-a-protocol-content-img-container{
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .workguide-better-than-a-protocol-content-img{
        max-width: 450px;
        width: 100%;
        height: 100%;
    }
}

@media screen and (min-width:681px) and (max-width: 1119px) {
    .workguide-better-than-a-protocol-main-container{
        padding: 72px 32px;
    }
    .workguide-better-than-a-protocol-title-container{
        padding-bottom: 64px;
    }

    .workguide-better-than-a-protocol-content-wrapper{
        box-sizing: border-box;
        width: 100%;
    }
    .workguide-better-than-a-protocol-header{
        padding-bottom: 16px;
    }
    .workguide-better-than-a-protocol-content-container{
        column-gap: 32px;
    }
    .workguide-better-than-a-protocol-content-description-container{
        /* order: 1; */
        /* width: 100% */
        flex-basis: 50%;
    }
    .workguide-better-than-a-protocol-content-img-container{
        /* order: 0; */
        /* width:100%; */
        flex-basis: 50%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .workguide-better-than-a-protocol-content-img{
        max-width: 450px;
        width: 100%;
        height: 100%
    }
}

@media screen and (max-width:680px) {
    .workguide-better-than-a-protocol-main-container{
        padding: 72px 12px;
    }
    .workguide-better-than-a-protocol-title-container{
        padding-bottom: 40px;
    }
    .workguide-better-than-a-protocol-content-wrapper{
        box-sizing: border-box;
        width: 100%;
    }
    .workguide-better-than-a-protocol-header{
        text-align: center;
        padding-bottom: 16px;
    }
    .workguide-better-than-a-protocol-content-container{
        flex-direction: column;
        row-gap: 24px;
        align-items: center;
    }
    .workguide-better-than-a-protocol-content-description-container{
        order: 1;
        width: 100%
    }
    .workguide-better-than-a-protocol-content-img-container{
        order: 0;
        width:100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .workguide-better-than-a-protocol-content-img{
        max-width: 450px;
        width: 100%;
        height: 100%;
    }
}
.workguide-better-than-a-protocol-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}