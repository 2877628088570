.class-content-overview-main-container{
    width: 100%;
}
.class-content-overview-header-container{
    width: 100%;
    /* height: 65vh; */
    /* min-height: 480px; */
}
.class-content-overview-header-img{
    width: 100%;
    object-fit: cover;
}
.class-content-overview-title-container{
    padding: 40px 20px 20px 20px;
}

.class-content-overview-info-container{
    width:80%;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}
.class-content-overview-info-section-container{
    width: 100%;
    display: flex;
    box-sizing: border-box;
}
.class-content-overview-info-section-title-container{
    display: flex;
    /* background-color: #fafafa; */
}
.class-content-overview-info-section-info-container{
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
}
.class-content-overview-chip-container{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    row-gap: 5px;
    column-gap: 5px;
    padding-top: 0.5em;
}
.class-content-overview-button{
    padding-top: 1em;
}

@media screen and (min-width:1120px) {
    .class-content-overview-header-img{
        height: 100%;
        min-height: 200px;
        max-height: 300px;
    }
    .class-content-overview-header-skeleton{
        height: 300px !important;
    }
    .class-content-overview-info-container{
        width:80%;
        margin: auto;
    }
    .class-content-overview-info-section-title-container{
        align-items: center;
        justify-content: flex-end;
        width:calc(3/12 * 100%) ;
    }
}

@media screen and (min-width:681px) and (max-width:1119px) {
    .class-content-overview-header-img{
        height: 100%;
        min-height: 200px;
        max-height: 300px;
    }
    .class-content-overview-header-skeleton{
        height: 300px !important;
    }
    .class-content-overview-info-container{
        width:80%;
        margin: auto;
    }
    .class-content-overview-info-section-title-container{
        align-items: center;
        justify-content: center;
    }
    .class-content-overview-info-section-title-container{
        align-items: center;
        justify-content: flex-end;
        width:calc(3/12 * 100%) ;
    }
}

@media screen and (max-width:680px) {
    .class-content-overview-header-img{
        height: 100%;
        min-height: 200px;
        max-height: 300px;
    }
    .class-content-overview-header-skeleton{
        height: 200px !important;
    }
    .class-content-overview-info-container{
        width:80%;
        margin: auto;
        display: flex;
        box-sizing: border-box;
    }
    .class-content-overview-info-section-container{
        flex-direction: column;
        padding: 40px 0px;
    }
    .class-content-overview-info-section-title-container{
        font-weight: bold !important;
    }
    .class-content-overview-info-section-info-container{
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 10px;
    }
}
