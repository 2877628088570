.pop-up-forget-password-dialog-container{
    padding: 20px
}
.pop-up-forget-password-dialog-success-container{
    padding: 0px 20px 20px 20px
}
.pop-up-forget-password-dialog-success-reminder{
    padding-top: 20px;
}
.pop-up-forget-password-dialog-success-animation-wrapper{
    width: 100px;
}