.content-multisystem-wrapper{
    display: flex;
    justify-content: center;
}
.content-multisystem-main-container{
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
}
.content-multisystem-content-container{
    display: flex;
    box-sizing: border-box;       
}
.content-multisystem-content-devices-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 24px;
}
.content-multisystem-content-devices-label{
    width:fit-content;
    margin: 0 auto;
}
.content-multisystem-content-devices-logo-container{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    row-gap: 16px;
    column-gap: 16px;
    align-items: center;
    justify-content: center;
}
.content-multisystem-content-multisystem-footlabel{
    padding-top: 8px;
}
@media screen and (min-width:1120px) {
    .content-multisystem-main-container{
        padding: 72px 160px;
    }
    .content-multisystem-title-container{
        padding-bottom: 64px;
    }
    .content-multisystem-subtitle-container{
        padding-bottom: 48px;
    }
    .content-multisystem-content-container{
        column-gap: 64px;
    }
    .content-multisystem-content-description-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-multisystem-content-multisystem-wrapper{
        flex-basis: 50%;
    } 
    .content-multisystem-content-multisystem-container{
        /* flex-basis: 50%; */
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        column-gap: 24px;
        padding: 32px;
        border-radius: 5px;
        flex-grow: 1;
    }
    .content-multisystem-content-devices-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-multisystem-main-container{
        padding: 72px 32px;
    }
    .content-multisystem-title-container{
        padding-bottom: 64px;
    }
    .content-multisystem-subtitle-container{
        padding-bottom: 48px;
    }
    .content-multisystem-content-container{
        column-gap: 32px;
    }
    .content-multisystem-content-description-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-multisystem-content-multisystem-wrapper{
        flex-basis: 50%;
    } 
    .content-multisystem-content-multisystem-container{
        /* flex-basis: 50%; */
        flex-grow: 1;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        row-gap: 24px;
        padding: 32px;
        border-radius: 5px;
    }
    .content-multisystem-content-devices-container{
        flex-basis: 100%;
    }
}
@media screen and (max-width: 680px) {
    .content-multisystem-main-container{
        /* padding: 48px 12px; */
        padding: 72px 12px;
    }
    .content-multisystem-title-container{
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .content-multisystem-subtitle-container{
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .content-multisystem-content-container{
        flex-direction: column;
        /* row-gap: 24px; */
        row-gap: 32px;
    }
    .content-multisystem-content-description-container{
        flex-basis: 100%;
        order: 1;
    }
    .content-multisystem-content-multisystem-wrapper{
        flex-basis: 100%;
        order: 0
    } 
    .content-multisystem-content-multisystem-container{
        /* flex-basis: 100%; */
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        /* row-gap: 24px; */
        row-gap: 32px;
        align-items: center;
        padding: 24px;
        border-radius: 5px;
    }
    .content-multisystem-content-devices-container{
        flex-basis: 100%;
    }
}
.content-multisystem-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}