.cancel-registered-user-meeting-no-modal-dialog-wrapper{
    min-height: 100px;
}
.cancel-registered-user-meeting-no-modal-dialog-meeting-badge-wrapper{
    position: relative;
    overflow-y: hidden;
}
.cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container{
    overflow-y: auto;
    max-height: 100px;
    padding: 10px 5px;
}
.cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-top{
    position:absolute;
    top:0px;
    left: 0px;
    height: 10px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-bottom{
    position:absolute;
    bottom:0px;
    left: 0px;
    height: 10px;
    width: 100%;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
.cancel-registered-user-meeting-no-modal-dialog-animation-container{
    height: 200px;
}
.cancel-registered-user-meeting-no-modal-dialog-error-title{
    font-weight: bold;
    padding-bottom: 20px;
}
.cancel-registered-user-meeting-no-modal-dialog-error-msg{
    white-space: pre-line;
    padding-bottom: 10px;
}