.fast-checkout-config-loading-container{
    height:200px
}
.fast-checkout-config-error-container{
    padding: 50px 0px ;
    align-items: center;
    column-gap: 8px;
}
.fast-checkout-config-details-container{
    padding-top: 20px
}
.fast-checkout-config-select-product-container{
    padding-top: 20px;
}
.fast-checkout-config-button-container{
    padding-top: 20px
}