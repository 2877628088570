.back-button{
    height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    /* color:#888; */
    opacity: 50%;
}
.back-button:hover{
    /* color: inherit; */
    opacity: 100%;
    background-color: #fafafa;
}
.back-button.disabled{
    opacity: 40%;
    pointer-events: none;
}