.content-slider-item-wrapper{
    /* Defined in the component 
    height: 480px;
    width: 296px;  
    */
    background-size: 100% 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    pointer-events: all;
    transform: translateY(0px);
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), background-size 0.5s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.content-slider-item-skeleton{
    /* Defined in the component 
    height: 480px !important;
    width: 296px !important;  
    */
    border-radius: 5px;
}
@media screen and (hover:hover) {
    .content-slider-item-wrapper:hover{
    transform: translateY(2px);
    background-size: 110% 110%;
    box-shadow: none !important;
    }
    .content-slider-item-info-container:hover{
        background-color: rgba(0, 0, 0, 0.25);
    }
}
/* Touchable devices */
.content-slider-item-wrapper:hover:active{
    transform: translateY(2px);
    background-size: 110% 110%;
    box-shadow: none !important;
}
/* Touchable devices */
.content-slider-item-info-container:hover:active{
    background-color: rgba(0, 0, 0, 0.25);
}
.content-slider-item-info-title{
    padding-bottom: 16px;
}
.content-slider-item-info-divider{
    height: 1px;
    margin: 0 auto 16px auto;
    width: 80px;
}
.content-slider-item-info-short-description{
    height: 75px;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
}
.content-slider-item-info-feature-icon{
    padding-bottom: 8px;
}
.content-slider-item-date-label{
    position: absolute;
    top: 16px;
    left: 16px;
}
.content-slider-item-chip{
    position: absolute;
    top: 16px;
    right: 16px;
}
.content-slider-item-info-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-left: 16px ;
    padding-right: 16px ;
    padding-bottom: 16px;
}
.content-slider-item-info-feature-container{
    display: flex;
    justify-content: center;
}
.content-slider-item-info-feature-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
} 
/* @media screen and (max-width: 340px){
    .content-slider-item-wrapper{
        width: inherit;
    }
}  */
.dragging{
    pointer-events: none;
}