.content-preview-and-description-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
@media screen and (min-width: 1120px) {
    .content-preview-and-description-main-container{
        padding-top: 72px;
        padding-bottom: 72px;
        row-gap: 72px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-preview-and-description-main-container{
        padding-top: 72px;
        padding-bottom: 72px;
        row-gap: 72px;
    }
}
@media screen and (max-width: 680px) {
    .content-preview-and-description-main-container{
        /* padding-top: 48px;
        padding-bottom: 48px;
        row-gap: 48px; */
        padding-top: 72px;
        padding-bottom: 72px;
        row-gap: 72px;
    }
}