.product-amount-main-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: fit-content;
    row-gap: 8px;
    column-gap: 8px;
}
.product-amount-final-with-promo-container{
    display: flex;
    column-gap: 4px;
    box-sizing: border-box;
    align-items: center;
    justify-content: inherit;
}