.my-content-wrapper{
    height: 100%;
    padding-top: 100px;
    padding-bottom: 70px;
    min-height: calc(100vh - 170px);
    min-height: calc(100dvh - 170px);
}
.my-content-main-container{
    max-width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
    /*  */
    min-height: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.my-content-elem-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    row-gap: 20px;
    padding-top: 20px;
    /*  */
    flex-grow: 1;
}
.my-content-loading-wrapper{
    flex-grow: 1;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
}
.my-content-no-content-animation-wrapper{
    flex-grow: 1;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
}
.my-content-no-content-animation-container{
    height: 100%;
    overflow-y: hidden;
    max-width: 380px;
    margin: 0px auto;
}

@media screen and (min-width:1120px) {
    .my-content-main-container{
        padding-left: 160px;
        padding-right: 160px;
    }
    .my-content-elem-container{
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .my-content-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
    .my-content-elem-container{
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width:680px) {
    .my-content-main-container{
        padding-left: 12px;
        padding-right: 12px;
    }
    .my-content-elem-container{
        padding-left: 10px;
        padding-right: 10px;
    }
}