.contact-support-section-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    row-gap: 24px;
}
.contact-support-section-img-container{
    position: relative;
    display: flex;
    box-sizing: border-box;
}
.contact-support-section-img-container img{
    height: 64px;
    width: 64px;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;

}
.contact-support-section-back-img{
    padding: 3px 8px;
    border: none !important;
    background-color:transparent !important;
}
.contact-support-section-front-img{
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%,0%);
    background-color:transparent !important;
    border: solid 3px #fff !important;
}
.contact-support-section-text-container{
    text-align: center;
}
@media screen and (min-width:681px) {
    .contact-support-section-wrapper{
        padding: 72px 0px
    }
}
@media screen and (max-width:680px){
    .contact-support-section-wrapper{
        padding: 72px 0px
    }
}