.toggle-msg-wrapper{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    margin-right: 20px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.toggle-msg-wrapper.absolute{
    position: absolute;
}
.toggle-msg-wrapper.show{
    opacity: 1;
    max-height: 200px;
    padding: 0px 0px 20px 20px;
}
.toggle-msg-wrapper.show-absolute{
    opacity: 1;
    max-height: 200px;
    padding: 0px 0px 0px 20px;
}
.toggle-msg-container{
    max-height: inherit;
    align-items: center;
}
.toggle-msg-container > div {
    max-height: inherit;
}