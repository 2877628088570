.big-footer-no-links-wrapper{
    display: flex;
    justify-content: center;
}
.big-footer-no-links-logo-container{
    padding-bottom: 24px;
}
.big-footer-no-links-main-container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
}
.big-footer-no-links-slogan-container{}

.big-footer-no-links-low-container{
    justify-content: space-between;
}
.big-footer-no-links-language-item{
    opacity: 0.3;
}
.big-footer-no-links-social-media-item > svg {
    /* height: 1.2em !important; */
    /* width: 1.2em !important; */
}
.big-footer-no-links-conditions-and-legal-container{
    padding-bottom: 8px;
}

@media screen and (min-width:681px) {
    .big-footer-no-links-main-container{
        padding:32px;
    }
    .big-footer-no-links-slogan-container{
        row-gap: 24px; 
        min-width: 300px;
        max-width: 350px;
        flex-basis: 300px
    }
    .big-footer-no-links-social-and-language-container{
        column-gap: 30px;
    }
    .big-footer-no-links-language-item:hover{
        opacity: 1;
    }
    .big-footer-no-links-social-media-container{
        flex-basis: 0;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: flex-end;
        column-gap: 10px;
    }
    .big-footer-no-links-horizontal-divider{
        margin: 32px 0px !important
    }
    @media screen and (min-width:1120px) {
        .big-footer-no-links-main-container{
            padding:40px;
        }
    }
}
@media screen and (max-width:680px) {
    .big-footer-no-links-main-container{
        padding:32px 12px 32px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .big-footer-no-links-slogan-container{
        row-gap: 16px; 
        padding-bottom: 32px;
        align-items: center;
    }
    .big-footer-no-links-slogan-item{
        max-width: 350px;
        text-align: center;
    }
    .big-footer-no-links-social-and-language-container{
        row-gap: 32px;
        align-items: center;
    }
    .big-footer-no-links-social-media-container{
        column-gap: 24px;
    }
    .big-footer-no-links-horizontal-divider{
        margin: 32px 0px !important;
        width: 100%;
    }
    .big-footer-no-links-conditions-and-legal-container{
        flex-direction: column;
        row-gap: 16px;
        padding-bottom: 32px;
        align-items: center;
    }
}