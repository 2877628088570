.user-meeting-status-widget-main-container{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    column-gap: 5px;
}
.user-meeting-status-widget-intermittent{
    animation: intermittent 1s infinite;

}
@keyframes intermittent {
    0%{
        opacity: 0;
    }
    30%,70%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}