.user-meeting-badge-main-container{
    padding: 10px;
    min-height: 60px;
    border-radius: 5px;
    border: solid 1px rgba(182, 182, 182, 0.3);
    background-color: #fafafa;
    align-items: center;
}
.user-meeting-badge-date{
    background-color: white;
    border-radius: 5px;
    border: solid 1px rgba(182, 182, 182, 0.3);
    height: 50px;
    width: 50px;
}
.user-meeting-badge-warning-icon-wrapper{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-meeting-badge-description-container{
    padding-left: 10px;
}
