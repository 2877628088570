.icon-label-wrapper{
    border-radius: 5px;
}
.icon-label-main-container{
    display: flex;
    border-radius: 5px;
    padding: 0px 4px;
    align-items: center;
    column-gap: 4px;
    box-sizing: border-box;
}