.section-header-wrapper{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
}
.section-header-wrapper-main-container{
    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;
}
@media screen and (min-width:1120px) {
    .section-header-wrapper-main-container{
        padding: 0px 160px
    }
    .section-header-wrapper-title{
        padding-bottom: 64px;
    }
    .section-header-wrapper-subtitle{
        padding-bottom: 48px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .section-header-wrapper-main-container{
        padding: 0px 32px
    }
    .section-header-wrapper-title{
        padding-bottom: 64px;
    }
    .section-header-wrapper-subtitle{
        padding-bottom: 48px;
    }
}
@media screen and (max-width:680px) {
    .section-header-wrapper-main-container{
        padding: 0px 12px
    }
    .section-header-wrapper-title{
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .section-header-wrapper-subtitle{
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center !important;
    }
}