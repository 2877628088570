.content-slider-item-responsive-wrapper{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    row-gap: 16px;
    padding: 16px;
}
.content-slider-item-responsive-featured-type-container{
    position:absolute; 
    top: 8px;
    left: 8px
}
.content-slider-item-responsive-text-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 8px;
}
.content-slider-item-responsive-description-container{
    /* mess with truncation adding extra space at the bottom */
    /* flex-grow: 1; */
    overflow: hidden;
    /* truncate */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    /* truncate ends */
} 
.content-slider-item-responsive-img-wrapper{
    /* width: 100%; */
    display:flex;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}
.content-slider-item-responsive-type-favorite-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content-slider-item-responsive-content-img-container{
    flex-grow: 1;
    /* harcoded height */
    /* height: 180px; */
    /* max-height: 180px; */
}
.content-slider-item-responsive-content-img-container > img{
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%
}
.content-slider-item-responsive-author-img-container{
    /* full height */
    /* width:40% */
    /* end: full height */
    /* small floating rounded profile  */
    position: absolute;
    bottom: 10px;
    right: 10px;
    /* end: small floating rounded profile  */
}
.content-slider-item-responsive-author-img-container.full-height{
    width:40%;
    position: static;
}
.content-slider-item-responsive-author-img-container > img{
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.content-slider-item-responsive-skeleton{
    width: 100% !important;
    height: 300px !important;
    border-radius: 5px;
}
