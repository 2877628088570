.featured-content-main-container{
    width:100%;
    height: fit-content;
    position: relative;
}
.featured-content-img{
    object-position: center;
    display:block;
}
.featured-content-loading-container{
    width: 100%;
    min-height: 536px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.featured-content-loading-container{
    width: 100%;
    min-height: 536px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.featured-content-info-wrapper{
    box-sizing: border-box;
    padding: 10px;
}
.featured-content-info-container{
    flex-wrap: nowrap !important;
}
.featured-content-info-svg-title{
    width: 100%;
    max-height: 200px;
}
.featured-content-pop-over-container{
    width: 100%;
    max-height: unset !important;
    max-width: 960px;
}
.featured-content-pop-over-video-container{
    margin: 0 auto;
    padding: 0px 10px;
    width: 100%;
    height: 100%;
    max-width: calc((100vh* 16 / 9) - 20px);/* includes padding */
    max-width: calc((100dvh* 16 / 9) - 20px);/* includes padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.featured-content-pop-over-close-icon{
    visibility:hidden;
    top: 10px;
    left: 20px;
    border-radius: 5px;
}
.featured-content-pop-over-container:hover .featured-content-pop-over-close-icon{
    visibility: unset;
    background-color: rgba(0,0,0,0.3)
}
.featured-content-info-wrapper-background-overlay{
    position: absolute;
    top:0px;
    left:0px;
    right: 0px;
    bottom: 0px;
}
@media (hover:none) {
    .featured-content-pop-over-close-icon{
        visibility: unset;
    }    
    .featured-content-pop-over-close-icon{
        background-color: rgba(0,0,0,0.3)
    }
}

@media screen and (min-width:1120px) {
    .featured-content-img{
        width:100%;
        min-height: 536px;
        height:fit-content;
        max-height: calc(100vh - 60px);
        max-height: calc(100dvh - 60px);
        object-fit: cover;
        border-radius: 5px;
    }
    .featured-content-info-wrapper{
        position: absolute;
        top:0;
        right:0;
        height: 100%;
        min-width: 320px;
        max-width: 400px;
    }
    .featured-content-info-container{
        height: 100%;
    }
    .featured-content-info-chip{
        padding-bottom: 8px;
    }
    .featured-content-info-svg-container{
        padding-bottom: 16px;
    }
    .featured-content-info-svg-author{
        padding-top: 8px;
    }
    .featured-content-info-title{
        padding-bottom: 16px;
    }
    .featured-content-info-divider{
        height: 1px;
        margin: 0 auto 16px auto;
        width: 80px;
    }
    .featured-content-info-button{
        padding-top: 64px;
    }
}
@media screen and (max-width:1119px) and (min-width:681px) {
    .featured-content-img{
        width:100%;
        height:fit-content;
        min-height: 536px;
        max-height: calc(100vh - 60px);
        max-height: calc(100dvh - 60px);
        object-fit: cover;
        border-radius: 5px;
    }
    .featured-content-info-wrapper{
        position: absolute;
        bottom:0;
        right:0;
        padding-top: 32px;
        padding-bottom: 32px;
        width: 100%;
    }
    .featured-content-info-container{
        width: 100%;
    }
    .featured-content-info-chip{
        padding-bottom: 8px;
    }
    .featured-content-info-svg-container{
        padding-bottom: 16px;
    }
    .featured-content-info-svg-title{
        max-height: 100px;
    }
    .featured-content-info-svg-author{
        padding-top: 8px;
    }
    .featured-content-info-title{
        padding-bottom: 16px;
    }
    .featured-content-info-divider{
        height: 1px;
        margin: 0 auto 16px auto;
        width: 80px;
    }
    .featured-content-info-button{
        padding-top: 24px;
    }
}
@media screen and (max-width:680px) {
    .featured-content-img{
        width:100%;
        /* min-height: 500px; */
        height:fit-content;
        max-height: calc(100vh - 60px);
        max-height: calc(100dvh - 60px);
        min-height: 536px;
        object-fit: cover;
        border-radius: 5px;
    }
    .featured-content-info-wrapper{
        position: absolute;
        bottom:0;
        right:0;
        padding-top: 32px;
        padding-bottom: 32px;
        width: 100%;
    }
    .featured-content-info-container{
        width: 100%;
    }
    .featured-content-info-chip{
        padding-bottom: 8px;
    }
    .featured-content-info-svg-container{
        padding-bottom: 16px;
    }
    .featured-content-info-svg-title{
        max-height: 80px;
    }
    .featured-content-info-svg-author{
        padding-top: 8px;
    }
    .featured-content-info-title{
        padding-bottom: 16px;
    }
    .featured-content-info-divider{
        height: 1px;
        margin: 0 auto 16px auto;
        width: 80px;
    }
    .featured-content-info-button{
        padding-top: 24px;
    }
}