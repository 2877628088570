.banner-api-connectivity-wrapper{
    display: flex;
    justify-content: center;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity 300ms ease-in-out, max-height 300ms ease-in-out;
}
.banner-api-connectivity-wrapper.show{
    opacity: 1;
    max-height: 1000px;
}
.banner-api-connectivity-main-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 60px;
    column-gap: 20px;
}
.banner-api-connectivity-content{
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    column-gap: 10px;
    align-items: center;
    box-sizing: border-box;
}
.banner-api-connectivity-icon-container{
    box-sizing: border-box;
    height: 100%;
}
.banner-api-connectivity-message-container{
    box-sizing: border-box;
}
.banner-api-connectivity-icon{
    width: 10px;
    height: 10px;
    border-radius: 100%;
}
.banner-api-connectivity-message{
    white-space: pre-wrap;
}
.banner-api-connectivity-loading-container{
    width: fit-content;
    height: fit-content;
}
@media screen and (min-width: 1120px) {
    .banner-api-connectivity-main-container{
        max-width: 1440px;
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (max-width: 1119px) and (min-width:681px) {
    .banner-api-connectivity-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (max-width: 680px) {
    .banner-api-connectivity-main-container{
        padding-left: 12px;
        padding-right: 12px;
    }
}