.landing-value-propositions-wrapper{
    display:flex;
    justify-content: center;
}
.landing-value-propositions-item-wrapper.last-child{
    padding-bottom: 0px;
}
@media screen and (min-width:1120px) {
    .landing-value-propositions-main-container{
        padding: 72px 160px;
        max-width: 1440px;
    }
    .landing-value-propositions-title{
        padding-bottom: 64px;
    }
    .landing-value-propositions-item-wrapper{
        padding-bottom: 64px;
    }
}
@media screen and (max-width:1119px) and (min-width:681px) {
    .landing-value-propositions-main-container{
        padding: 72px 32px;
    }
    .landing-value-propositions-title{
        padding-bottom: 64px;
    }
    .landing-value-propositions-item-wrapper{
        padding-bottom: 64px;
    }
}
@media screen and (max-width:680px){
    .landing-value-propositions-main-container{
        padding: 48px 12px;
    }
    .landing-value-propositions-title{
        padding-bottom: 40px;
    }
    .landing-value-propositions-item-wrapper{
        padding-bottom: 40px;
    }
}