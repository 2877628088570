.class-content-system-edit-main{
}
.class-content-system-edit-header{
    height: 80px;
    align-items: center;
    justify-content: center;
}
.class-content-system-edit-header-icon{
    margin-right: 10px
}
.class-content-system-edit-form{
    min-height: 150px;
    padding: 20px;
}
.class-content-system-edit-form-item-default{
    font-style: italic;
    display: inline;
}
.class-content-system-edit-form-button{
    padding-top: 20px;
}