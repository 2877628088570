.mailing-list-subscription-section-wrapper{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.mailing-list-subscription-section-title{
    padding-bottom: 40px;
}
.mailing-list-subscription-section-main-container{
    max-width: 1440px;
}
@media screen and (min-width:1120px) {
    .mailing-list-subscription-section-main-container{
        padding: 72px 160px;
        box-sizing: border-box;
    }
    .mailing-list-subscription-section-subtitle{
        padding-bottom: 0px;
    }
    .mailing-list-subscription-section-bottom-container{
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        column-gap: 32px;
        /* align-items: center;  */
    }
    .mailing-list-subscription-section-text-container{
        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 0;
        box-sizing: border-box;
    }
    .mailing-list-subscription-section-form-container{
        width: 500px;
        box-sizing: border-box;
    }
}
@media screen and (max-width:1119px)  {
    .mailing-list-subscription-section-main-container{
        box-sizing: border-box;
        /* max-width: 620px; */
    }
    .mailing-list-subscription-section-subtitle{
        padding-bottom: 24px;
    }
    .mailing-list-subscription-section-subtitle{
        text-align: center;
    }
    .mailing-list-subscription-section-bottom-container{
        display: flex;
        box-sizing: border-box;
        flex-wrap: wrap;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center; 
        max-width: 620px;
    }
    .mailing-list-subscription-section-text-container{
        box-sizing: border-box;
        text-align: center;
    }
    .mailing-list-subscription-section-form-container{
        box-sizing: border-box;
    }
    @media screen and (min-width:681px) and (max-width:1119px)  {
        .mailing-list-subscription-section-main-container{
            padding: 72px 32px;
        }
        .mailing-list-subscription-section-bottom-container{
            row-gap: 24px;
        }
        .mailing-list-subscription-section-form-container{
            width: 500px;
        }
    }
    @media screen and (max-width:680px) {
        .mailing-list-subscription-section-main-container{
            padding: 72px 12px;
        }
        .mailing-list-subscription-section-bottom-container{
            row-gap: 24px;
        }
        .mailing-list-subscription-section-form-container{
            width: 100%;
        }
    }
}
