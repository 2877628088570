.checkout-add-subscription-main-container{
    border-radius: 5px;
    border: 0.5px solid;
    animation: open;
    position: relative;
    animation-duration: 0.3s;
}
@keyframes open {
    from {
        max-height: 0px;
        opacity: 0%;
    }
    to{
        max-height: 1000px;
        opacity: 100%;
        
    }
}
.checkout-add-subscription-subcontainer{
    padding: 10px
}
.checkout-add-subscription-title-container{
    align-items: center;
    column-gap: 4px;
}
.checkout-add-subscription-ribbon-container{
    position: absolute;
    top: -5px;
    right: 16px;
    filter: drop-shadow(1px 2px 2px rgba(0,0,0,0.32));
}
.checkout-add-subscription-button-container{
    border-top: 0.5px solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.checkout-add-subscription-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.checkout-add-subscription-button:hover{
    background-color: rgba(255, 255, 255, 0.3);
}
.checkout-add-subscription-button.disabled{
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}
.checkout-add-subscribe-button-text{
    opacity: 100%;
}
.checkout-add-subscribe-button-text.disabled{
    opacity: 40%;
}
.checkout-add-subscription-button-circle-progress{
}
.checkout-add-subscription-subcontainer ul {
    margin: 5px 0px 0px 0px;
}