.button-loading-progress-container-hidden{
    width: 0px;
    display: flex;
    align-items: center;
    transition: width ease-in 0.3s
}

.button-loading-progress-container-active{
    margin-left: 10px;
    width: 2em;
    height: 2em;
}
