.landing-hero-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    /* prevent background img overflow */
    overflow: hidden;
}
.landing-hero-background-img{
    position:absolute;
    height: 100%;
    top:0px;
    left: 50%;
    z-index: 0;
    max-width: 1440px;
}
.landing-hero-main-container{
    /* position in front of background img */
    z-index: 0;
}
.landing-hero-img-container{
    position: relative;
}
.landing-hero-img-animation{
    position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}
.landing-hero-img-animation.show{
    opacity: 1;
}
.landing-hero-img{
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}
.landing-hero-img.show{
    opacity: 1;
}
@media screen and (min-width: 1120px) {
    .landing-hero-main-container{
        padding-left: 160px;
        padding-right: 160px;
        padding-top: 72px;
        padding-bottom: 72px;
        min-height: calc(100vh - 140px);
        min-height: calc(100dvh - 140px);
        max-height: 1000px;
        max-width: 1440px;
    }
    .landing-hero-title{
        padding-bottom: 24px;
    }
    .landing-hero-subtitle{
        padding-bottom: 24px;
    }
    .landing-hero-text-container{
        padding-right: 32px;
    }
    .landing-hero-img-container{
        height: 100%;
        min-width: 475px;
    }    
    .landing-hero-img-animation{
        height: 100%;
    }
    .landing-hero-img{
        max-width: 475px;
        object-fit: contain;
        height: 100%;
        /* height: 420px; */
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px){    
    .landing-hero-main-container{
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 72px;
        padding-bottom: 72px;
        min-height: calc(100vh - 140px);
        min-height: calc(100dvh - 140px);
    }
    .landing-hero-text-container{
        max-width: 640px;
    }
    .landing-hero-title{
        padding-bottom: 24px;
    }
    .landing-hero-subtitle{
        padding-bottom: 24px;
    }
    .landing-hero-img-container{
        padding-bottom: 24px;
        opacity: 0;
        max-height: 0px;
        transition: max-height ease-in-out 300ms, opacity ease-in-out 300ms;
        /* min-height: calc(420px + 24px); */
    }
    .landing-hero-img-container.show{
        opacity: 1;
        max-height: 1000px;
    }  
    .landing-hero-img{
        /* width: 387px; */
        /* height: 342px; */
        /* max-width: ; */
        /* width: 100%; */
        object-fit: contain;
        height: 100%;
        max-width: 100%;
    }
}
@media screen and (max-width: 680px){
    .landing-hero-main-container{
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 72px;
        padding-bottom: 72px;
        min-height: calc(100vh - 140px);
        min-height: calc(100dvh - 140px);
    }
    .landing-hero-title{
        padding-bottom: 24px;
    }
    .landing-hero-subtitle{
        padding-bottom: 24px;
    }
    .landing-hero-img-container{
        padding-bottom: 24px;
        opacity: 0;
        max-height: 0px;
        transition: max-height ease-in-out 300ms, opacity ease-in-out 300ms;
        /* min-height: calc(260px + 24px); */
    }
    .landing-hero-img-container.show{
        opacity: 1;
        max-height: 1000px;
    }   
    .landing-hero-img{
        /* max-width: 320px; */
        max-height: 260px;
        /* min-width: 256px; */
        /* min-height: 227px; */
        object-fit: contain;
        height: 100%;
        max-width: 100%;
    }
}
