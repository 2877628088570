.checkout-billing-address-edit-pop-up-header{
    height: 80px;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5 solid ;
}
.checkout-billing-address-edit-pop-up-form-container{
    min-height: 150px;
}
.checkout-billing-address-edit-pop-up-form-country{
    max-height: 1000px;
    opacity: 100%;
    text-align: center;
    padding: 20px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-billing-address-edit-pop-up-form-country.hidden{
    pointer-events: none;
    height: 0px;
    max-height: 0px;
    opacity: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 40px;
}
.checkout-billing-address-edit-pop-up-form-address{
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
    transition-delay: 0.3s;
}
.checkout-billing-address-edit-pop-up-form-address.show{
    max-height: 1000px;
    opacity: 100%;
    padding: 20px;
}
.checkout-billing-address-edit-pop-up-feedback-toogle {
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-billing-address-edit-pop-up-feedback-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}
.checkout-billing-address-edit-pop-up-form-button{
    padding-top: 20px;
}

