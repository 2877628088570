.check-subscription-status-wrapper{
    margin: 0 auto;
    max-width: 1440px;
}
.check-subscription-status-main-container{
    padding-top: 80px;
    padding-bottom: 80px;
    box-sizing: border-box;
    min-height:100vh;
    min-height:100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-subscription-status-msg-container{
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
    row-gap: 10px;
    flex-basis: 380px;
}