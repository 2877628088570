.login-form-menu-item {
    padding-bottom:10px
}
.login-form-menu-button{
    padding-bottom:20px ;
}

.login-form-text-cickable{
    cursor: pointer;
}

.login-form-checkbox{
    padding-right: 5px;
}

.login-form-unknwon-error-container{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.login-form-unknwon-error-container.show{
    opacity: 1;
    max-height: 50px;
    padding: 0px 0px 20px 20px;
}