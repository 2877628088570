.checkout-sales-tax-details-button{
    padding: 0px 5px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 50%;
}
.checkout-sales-tax-details-button:hover{
    /* Grey[100] */
    background-color: #fafafa;
    opacity: 100%;
}
.checkout-sales-tax-details-toggle {
    padding-left: 5px;
    max-height: 0px;
    opacity: 0%;
    transition: max-height 0.3s ease-in-out,  opacity 0.3s ease-in-out;
}
.checkout-sales-tax-details-toggle-item{
    padding: 0px 5px;
}
.checkout-sales-tax-details-toggle.open{
    max-height: 3000px;
    opacity: 100%
}