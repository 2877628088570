
.checkout-item-quantity-main-container{
}
.checkout-item-quantity-label{
    padding-right: 5px;
}
.checkout-item-quantity-button{
    cursor: pointer;
    border-radius: 5px;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkout-item-quantity-button:hover{
    background-color: #fafafa;
}
.checkout-item-quantity-button.loading{
    pointer-events: none;
    opacity: 40%;
}
.pop-up-check-out-item-quantity-input{
    border:none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 40px;
    padding: 0px;
}
.pop-up-check-out-item-quantity-input:focus{
    border:none;
    background-color: transparent;
    outline: none;
}
