.checkout-billing-address-edit-form-container{
    min-height: 320px;
}
.checkout-billing-address-edit-form-country{
    max-height: 1000px;
    opacity: 100%;
    text-align: center;
    padding: 20px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-billing-address-edit-form-country.hidden{
    pointer-events: none;
    height: 0px;
    max-height: 0px;
    opacity: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 40px;
}
.checkout-billing-address-edit-form-address{
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
    transition-delay: 0.3s;
}
.checkout-billing-address-edit-form-address.show{
    max-height: 1000px;
    opacity: 100%;
    padding: 20px;
}
.checkout-billing-address-edit-feedback-toogle {
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-billing-address-edit-feedback-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}
.checkout-billing-address-edit-form-button{
    padding-top: 20px;
}

