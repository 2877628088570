.class-content-user-system-select-form-msg{
    text-align: right;
    
}
.class-content-user-system-select-form-msg-disabled{
    opacity: 0.3;
}
.class-content-user-system-select-form-item{
    padding:20px;
}
.class-content-user-system-select-form-container-animation{
    animation: show 1s;
}
@keyframes show {
    from{
        /* padding-left: 10px; */
        opacity: 0%;
        transform: translateX(-10px);
    }
    to{
        /* padding-left: 0px; */
        opacity: 100%;
        /* transform: translateX(0px); */
    }
}