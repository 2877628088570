.user-general-menu-main-container{
}
.user-general-menu-title{
    padding-bottom: 20px;
}
.user-general-menu-group-title{
    font-weight: bold !important;
}
.user-general-menu-content-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 20px;
}
.user-general-menu-inline-field-group-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    column-gap: 10px;
}
.user-general-menu-field-value-button-container{
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    align-items: center;
    column-gap: 10px;
    justify-content: flex-end;
}
.user-general-menu-field-load-button-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.user-general-menu-field-value{
    flex-grow: 1;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-general-menu-group-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 10px;
}
.user-general-menu-button-save-locale{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 10px;
}
.user-general-menu-update-email-confirmation-popup-email-container{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    column-gap: 8px;    
}
.user-general-menu-update-email-confirmation-popup-email{
    line-break: anywhere;
    font-style: italic;
    text-align: center;
}
@media screen and (min-width:1120px) {
    
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .user-general-menu-inline-field-group-container{
        align-items: normal;
        flex-direction: column;
    }
    .user-general-menu-field-value{
        text-align: start;
    }
}
@media screen and (max-width:680px) {
    .user-general-menu-inline-field-group-container{
        align-items: normal;
        flex-direction: column;
    }
    .user-general-menu-field-value{
        text-align: start;
    }
}