.user-note-fullscreen-wrapper{
}
.user-note-fullscreen-container{
    position:relative;
    height: 100%;
    width: 100%;
}
.user-note-fullscreen-read-nav-container{
    align-items: center;
    border-bottom: solid 1px #f3f3f3;
    height: 40px;
}
.user-note-fullscreen-read-bottom-info-wrapper{
    max-height: 50px;
}
.user-note-fullscreen-read-bottom-info-container{
    justify-content: space-between;
    padding-top: 10px;
}
.user-note-fullscreen-editor{
}
.user-note-fullscreen-nav-elem-wrapper{
    padding: 0px 5px;
}
.user-note-fullscreen-button-icon{
    font-size: 20px !important;
    display: block !important;
    cursor: pointer;
    opacity: 0.3;
}
.user-note-fullscreen-button-icon:hover{
    opacity: 1;
}

.user-note-fullscreen-edit-button-container{
    /* justify-content: end; */
}
.user-note-fullscreen-edit-error-toogle{
    position: absolute;
    z-index: 2;
    top: 40px;
    text-align: center;
    max-height: 0px;
    width: 100%;
    overflow-y: hidden;
    opacity: 0%;
    padding: 0px;
    white-space: pre-line;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-top 0.3s ease-in-out;
}
.user-note-fullscreen-edit-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding: 10px 0px;
}

