.loading-component-main-container{
    width: 100%;
    height: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading-component-bar-progress{
    width: 100%;
}
.loading-component-circular-progress{
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center
}