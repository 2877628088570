.class-side-bar-main{
    /* position: fixed; */
    /* width:100%; */
    overflow-y: auto;
    height: 100%;
    padding: 10px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 8px;
}

.class-side-bar-section-overview{
}
.class-side-bar-section-wrapper{

}
.class-side-bar-section-container{
    /* Added 11/09 */
    position:relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
}
.class-side-bar-section-name-wrapper{
    flex-grow: 1;
}
.class-side-bar-section-closed{
    max-height:0px;
    visibility: hidden;
    display: none;
    transition: max-height ease-out 0.1s
}

.class-side-bar-section-opened{
    display: inherit;
    max-height:2000px; /* It could be removed */
    animation-name: showSection;
    animation-duration: 0.2s;
    transition: max-height ease-in 0.2s
}

.class-side-bar-section-marker-container{
    width:5px;
    /* Added 11/09 */
    position: absolute;
    height: 100%;
    /* height:auto */
}

.class-side-bar-section-marker{
    height: 100%;
    max-width: 0%

}
.class-side-bar-section-marker-selected{
    max-width: 100%;
}

@keyframes showSection {
    0%{
        opacity: 0%;
        visibility: hidden;
    }
    100%{
        visibility: visible;
        opacity: 100%;
    }
    
}

