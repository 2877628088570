.social-comment-section-wrapper{
    display: flex;
    justify-content: center;
}
.social-comment-section-main-container{
    box-sizing: border-box;
    width: 100%
}
.social-comment-section-comments-container{
    display: flex;
}
.social-comment-section-comment-item{
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
    /* This set item height to fit its content instead of the row height */
    height: fit-content;
}
@media screen and (min-width:681px) {
    .social-comment-section-main-container{
        padding: 72px 160px;
        max-width: 1440px;
    }
    .social-comment-section-title{
        padding-bottom: 48px;
    }
    .social-comment-section-comments-container{
        justify-content: space-between;
        column-gap: 32px;
    }
    .social-comment-section-comment-item{
        width: 33%
    }

}
@media screen and (min-width: 681px) and (max-width:1119px) {
    .social-comment-section-main-container{
        padding: 72px 32px;
    }
    .social-comment-section-comments-container{
        column-gap: 16px;
    }
    .social-comment-section-comment-item{
        width: 33%;
    }
}
@media screen and (max-width:680px) {
    .social-comment-section-main-container{
        /* padding: 48px 12px; */
        padding: 72px 12px;
    }
    .social-comment-section-title{
        padding-bottom: 48px;
        text-align: center;
    }
    .social-comment-section-comments-container{
        flex-direction: column;
        row-gap: 32px;
    }
}