.checkout-details-main-container{
}
.checkout-details-main-amount p{
    font-weight: bold !important;
}
.checkout-details-first-item{
    padding-bottom: 10px;
}
.checkout-details-item{
}
.checkout-details-middle-amount-item{
    padding: 10px 0px 10px 5px;
}
.checkout-details-last-item{
    padding-top: 10px;
}