.class-side-bar-section-main{
    cursor: pointer;
    min-height: 3em;
}

.class-side-bar-section-info-container{
    height: 100%;
    padding: 1em;
}
.class-side-bar-section-name{
}
.class-side-bar-section-progress{
}