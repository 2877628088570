.class-content-language-edit-main{
}
.class-content-language-edit-header{
    height: 80px;
    align-items: center;
    justify-content: center;
}
.class-content-language-edit-header-icon{
    margin-right: 10px
}
.class-content-language-edit-form{
    min-height: 150px;
    padding: 20px;
}
.class-content-language-edit-form-button{
    padding-top: 20px;
}