.user-content-product-preview-wrapper{
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.user-content-product-preview-main-container-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
}
.user-content-product-preview-main-container{
    display: flex;
    width: 100%;
    box-sizing: border-box;
}
.user-content-product-preview-background{
    position:absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
}
.user-content-product-preview-is-subscribed-container{
    position: absolute;
    padding: 10px;
    border-radius: 50%;
}
.user-content-product-preview-img-container{
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
}

.user-content-product-preview-img{
    object-fit: cover;
}
.user-content-product-preview-info-container{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    overflow: hidden;
}
.user-content-product-preview-info-author-container{
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    box-sizing: border-box;
    column-gap: 16px;
}
.user-content-product-preview-info-description-container{
    flex-grow: 1;
}
.user-content-product-preview-info-progress-container{
}
.user-content-product-preview-info-progress-bar-container{
    display: flex;
    width: 100%;
    box-sizing: border-box;   
    align-items: center;
    column-gap: 8px;
}
.user-content-product-preview-info-completion-date-container{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    column-gap: 4px;
}
.user-content-product-preview-info-progress-bar{
    flex-grow: 1;
    overflow: hidden;
    padding-top: 8px;
    box-sizing: border-box;
}
.user-content-product-preview-action-container{
    margin: auto;
}
.user-content-product-preview-bottom-row{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: end;
    min-height: 20px;
}
.user-content-product-preview-truncated-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.user-content-product-preview-alert-container{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

@media screen and (min-width:1120px) {
    .user-content-product-preview-main-container-wrapper{
        padding:20px;
        row-gap: 20px;
    }
    .user-content-product-preview-main-container{
        column-gap: 20px;
    }
    .user-content-product-preview-info-container{
        row-gap: 4px;
    }
    .user-content-product-preview-bottom-row{
        padding: 0px 10px;
    }
    .user-content-product-preview-is-subscribed-container{
        top: 10px;
        left: 10px
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .user-content-product-preview-main-container-wrapper{
        padding:20px;
        row-gap: 20px;
    }
    .user-content-product-preview-main-container{
        column-gap: 20px;
    }
    .user-content-product-preview-info-container{
        overflow: hidden;
        row-gap: 4px;
    }
    .user-content-product-preview-bottom-row{
        padding: 0px 10px;
    }
    .user-content-product-preview-is-subscribed-container{
        top: 10px;
        left: 10px
    }
}
@media screen and (max-width:680px) {
    .user-content-product-preview-main-container-wrapper{
        padding:10px;
        row-gap: 10px;
    }
    .user-content-product-preview-main-container{
        flex-direction: column;
        row-gap: 10px;
    }
    .user-content-product-preview-img-container{
        width: 100% !important;
    }
    .user-content-product-preview-img{
        width: 100% !important;
    }
    .user-content-product-preview-info-description-container{
        min-height: 50px;
    }
    .user-content-product-preview-action-container{
        margin: unset;
    }
    .user-content-product-preview-is-subscribed-container{
        top: 5px;
        left: 5px
    }
}