.single-content-itinerary-wrapper{
    display: flex;
    justify-content: center;
}
.single-content-itinerary-main-container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
}
.single-content-itinerary-content-in-numbers-container{
    display: flex;
    column-gap: 8px;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 16px;
}
.single-content-itinerary-content-in-numbers-dot{
    flex-shrink: 0;
}
.single-content-itinerary-content-sections-container{
    flex-grow: 1;
    box-sizing: border-box;
}
.single-content-itinerary-content-section-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-height:0px;
    overflow-y: hidden;
    transition: ease-in-out max-height 0.3s
}
.single-content-itinerary-content-section-container.show{
    max-height: 1000000px;
}
.single-content-itinerary-content-section-title-and-num{
    display: flex;
    box-sizing: border-box;
    column-gap: 16px;
}
.single-content-itinerary-content-section-num-container{
    display: flex;
    flex-direction: column;
}
.single-content-itinerary-content-section-num-dashed-line{
    flex-grow: 1;
    min-width: 15px; /* match half section num width */
    width: 15px; /* match half section num width */
}
.single-content-itinerary-content-section-num{
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}
.single-content-itinerary-content-section-title{
    cursor: pointer;
    min-height: 30px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}
.single-content-itinerary-content-section-description-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 16px;
}
.single-content-itinerary-content-section-description-dashed-line{
    min-width: 15px; /* match half section num width */
    margin-right: 15px;
}
.single-content-itinerary-content-section-description{
    width: 100%;
    padding: 16px 0px;
    box-sizing: border-box;
    transition: ease-in-out padding 0.3s;
}
.single-content-itinerary-content-section-description.show{
    transition: ease-in-out padding 0.3s 0.3s;
}
.single-content-itinerary-content-section-description p{
    max-height:0px;
    overflow-y: hidden;
    transition: ease-in-out max-height 0.3s;
}
.single-content-itinerary-content-section-description.show p{
    max-height: 1000000px;
    transition: ease-in-out max-height 0.3s 0.3s;
}
.single-content-itinerary-sections-show-more-wrapper{
    display: flex;
    box-sizing: border-box;
    justify-content: center;
}
.single-content-itinerary-sections-show-more-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    column-gap: 8px;
    padding-top: 16px;
}
.single-content-itinerary-content-download-itinerary-container{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
@media screen and (min-width: 1120px) {
    .single-content-itinerary-main-container{
        padding: 72px 160px;
    }
    .single-content-itinerary-title-container{
        padding-bottom: 64px;
    }
    .single-content-itinerary-subtitle-container{
        padding-bottom: 48px;
    }
    .single-content-itinerary-content-sections-and-lessons-container{
        display: flex;
        box-sizing: border-box;
        column-gap: 64px;
    }
    .single-content-itinerary-content-sections-container{
        flex-basis: 50%;
        box-sizing: border-box;
        flex-grow: 1;
    }
    .single-content-itinerary-content-section-description.show{
        padding: 32px 0px;
    }
    .single-content-itinerary-content-lessons-container{
        flex-basis: 50%;
        box-sizing: border-box;
    }
    .single-content-itinerary-content-lessons-wrapper{
        position: sticky;
        top: 25%
    }
    .single-content-itinerary-content-download-itinerary-container{
        padding-top: 32px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .single-content-itinerary-main-container{
        padding: 72px 32px;
    }
    .single-content-itinerary-title-container{
        padding-bottom: 64px;
    }
    .single-content-itinerary-subtitle-container{
        padding-bottom: 48px;
    }
    .single-content-itinerary-content-sections-and-lessons-container{
        width: 100%;
        box-sizing: border-box;
    }
    .single-content-itinerary-content-section-description{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 24px;
    }
    .single-content-itinerary-content-section-description.show{
        padding: 32px 0px 40px 0px;
    }
    .single-content-itinerary-content-lessons-container{
        box-sizing: border-box;
        max-height:0px;
        overflow-y: hidden;
        transition: ease-in-out max-height 0.3s;
    }
    .single-content-itinerary-content-lessons-container.show{
        box-sizing: border-box;
        max-height: 1000000px;
        transition: ease-in-out max-height 0.3s 0.3s;
    }
    .single-content-itinerary-content-download-itinerary-container{
        padding-top: 32px;
    }
}
@media screen and (max-width: 680px) {
    .single-content-itinerary-main-container{
        /* padding: 48px 12px; */
        padding: 72px 12px;
    }
    .single-content-itinerary-title-container{
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .single-content-itinerary-subtitle-container{
        /* padding-bottom: 16px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .single-content-itinerary-content-in-numbers-container{
        justify-content: center;
        align-items: center;
    }
    .single-content-itinerary-content-section-num{
        border-radius: 0px 5px 5px 0px;
    }
    .single-content-itinerary-content-section-num-dashed-line{
        flex-grow: 1;
        min-width: 0px; /* match half section num width */
        width: 0px; /* match half section num width */
    }
    .single-content-itinerary-content-section-description-dashed-line{
        min-width: 0px; /* match half section num width */
        margin-right: 0px;
    }
    .single-content-itinerary-content-sections-and-lessons-container{
        width: 100%;
        box-sizing: border-box;
    }
    .single-content-itinerary-content-section-description{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 24px;
    }
    .single-content-itinerary-content-section-description.show{
        padding: 32px 0px 40px 0px;
    }
    .single-content-itinerary-content-lessons-container{
        box-sizing: border-box;
        max-height:0px;
        overflow-y: hidden;
        transition: ease-in-out max-height 0.3s;
    }
    .single-content-itinerary-content-lessons-container.show{
        box-sizing: border-box;
        max-height: 1000000px;
        transition: ease-in-out max-height 0.3s 0.3s;
    }
    .single-content-itinerary-content-download-itinerary-container{
        padding-top: 32px;
    }

}
.single-content-itinerary-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}

