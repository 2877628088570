.count-down-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.count-down-counter-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 8px;
}
.count-down-counter-container-item{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
}
.count-down-counter-digits-container{
    display: flex;
    box-sizing: border-box;
}
.count-down-counter-digit-container{
}
@media screen and (max-width:680px) {
    .count-down-counter-container{
    }
}