.landing-features-wrapper{
    display: flex;
    justify-content: center;
}
@media screen and (min-width:1120px){
    .landing-features-main-container{
        padding: 60px 148px;
        max-width: 1440px;
    }
    .landing-features-feature-wrapper{
        padding: 12px;
        width: 25%;
    }
}
@media screen and (min-width:681px) and (max-width:1119px){
    .landing-features-main-container{
        padding: 72px 24px;
    }
    .landing-features-feature-wrapper{
        padding: 8px;
        width: 25%;
    }
}
@media screen and (max-width:680px){
    .landing-features-main-container{
        padding: 48px 8px;
    }
    .landing-features-feature-wrapper{
        padding: 8px;
        width: 50%;
    }
}
@media screen and (max-width:400px){
    .landing-features-main-container{
        padding: 48px 8px;
    }
    .landing-features-feature-wrapper{
        padding: 8px;
        width: 100%;
    }
}