.user-note-wrapper{
}
.user-note-container{
    min-height: 50px;
    width: 100%;
    padding: 10px;
}
.user-note-read-upper-info-container{
    justify-content: space-between;
    padding-bottom: 5px;
}
.user-note-read-bottom-info-wrapper{
    opacity: 0;
    max-height: 0px;
    overflow-y: hidden;
    transition: opacity ease-in-out 0.3s, max-height ease-in-out 0.3s;
}
.user-note-read-bottom-info-wrapper.show{
    opacity: 1;
    max-height: 50px;
}
.user-note-read-bottom-info-container{
    justify-content: space-between;
    padding-top: 10px;
}
.user-note-editor{
    min-height: 30px;
    height: fit-content;
    /* padding-bottom: 1em; */
}
.user-note-button-wrapper{
    padding: 0px 5px;
}
.user-note-button-icon{
    font-size: 20px !important;
    display: block !important;
    cursor: pointer;
    opacity: 0.3;
}
.user-note-button-icon:hover{
    opacity: 1;
}
.user-note-full-screen-button-wrapper{
    opacity: 0;
    transition: opacity ease-in-out 0.3s !important;
}
.user-note-full-screen-button-icon{
    font-size: 16px !important;
    display: block !important;
    cursor: none;
    opacity: 0.3;
}
.user-note-full-screen-button-icon:hover{
    opacity: 1;
}
.user-note-expand-button-wrapper{
    transition: transform 0.1s ease-in-out;
}
.user-note-edit-bottom-info-container{
    justify-content: space-between;
}
.user-note-edit-error-toogle{
    text-align: left;
    max-height: 0px;
    overflow-y: hidden;
    opacity: 0%;
    padding: 0px;
    white-space: pre-line;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.user-note-edit-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
    padding-top: 10px;
}

