.social-comment-item-main-container{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    row-gap: 8px;
}
.social-comment-item-message-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.social-comment-item-user{
    display: flex;
    box-sizing: border-box;
    align-items: flex-end;
    gap: 16px;
}
.social-comment-item-user-img-container{
    /* overflow: hidden; */
}
.social-comment-item-user-name-position-container{
}