.forget-password-input-wrapper{
    padding: 20px 0px 20px 0px
}
.forget-password-button{
    padding-top:20px;
}
.forget-password-error-container{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.forget-password-error-container.show{
    opacity: 1;
    max-height: 50px;
    padding: 20px 0px 0px 20px;
}