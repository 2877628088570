.add-to-subscription-no-modal-dialog-main-container{
    padding: 20px;
}
.add-to-subscription-no-modal-dialog-warning-toggle{
    opacity: 0%;
    max-height: 0px;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
}
.add-to-subscription-no-modal-dialog-warning-toggle.open{
    opacity: 100%;
    max-height: 1000px;
}
.add-to-subscription-no-modal-dialog-add-items-container{
    padding: 0px 8px;
}