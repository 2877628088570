.pop-up-find-specialist-title{
    padding: 40px 20px 0px 20px
}
.pop-up-find-specialist-form-wrapper{
    padding: 20px 20px 20px 20px
}
.pop-up-find-specialist-form-submited-container{
    text-align: center;
    padding: 20px;
}
.pop-up-find-specialist-form-submitted-animation{
    width: 100px;
    margin: 0 auto;
}
.pop-up-find-specialist-form-submitted-msg{
    white-space: pre-wrap;
}
.pop-up-find-specialist-form-button-container{
    text-align: center;
}
.pop-up-find-specialist-toggle-msg{
    bottom: 20px;
    background: rgba(255, 255, 255, 0.95);
}