.pop-up-simple-feedback-container{
    text-align: center;
    padding: 20px;
}
.pop-up-simple-feedback-animation{
    width: 100px;
    margin: 0 auto;
}
.pop-up-simple-feedback-msg{
    white-space: pre-wrap;
}