
.login-signin-item {
    padding-bottom:20px
}

.login-signin-link{
    text-decoration: none;
    cursor: pointer;
}

.login-signin-link:hover{
    text-decoration: underline;
}
.login-signin-link.disabled{
    opacity: 0.4;
    pointer-events:none;
}
.login-signin-container-animation{
    animation: show 1s;
}
@keyframes show {
    from{
        /* padding-left: 10px; */
        opacity: 0%;
        transform: translateX(-10px);
    }
    to{
        /* padding-left: 0px; */
        opacity: 100%;
        /* transform: translateX(0px); */
    }
}
