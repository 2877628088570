.checkout-promo-code-main-container{
    width: 100%
}
.checkout-promo-code-timer-container{
    display: flex;
    justify-content: space-between;
    opacity: 0%;
    color:#888;
    max-height: 0px;
    padding: 0px 10px;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}
.checkout-promo-code-timer-container.show{
    opacity: 100%;
    max-height: 100px;
    padding-bottom: 5px;
}
.checkout-promo-code-timer{}
.checkout-promo-code-input-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    font-size: 1em;
    height: 2.5em;
    border-width: 1px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 5px;
    transition: width 0.2s ease-in-out;
}
.checkout-promo-code-input-container.expand{
    width: calc(100% - 5px);
}
.checkout-promo-code-input-wrapper{
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
}
.checkout-promo-code-input{
    width:100%;
    font-size: 1em;
    border-style: none;
    outline: none;
    background-color: transparent;
}
.checkout-promo-code-input:disabled{
    background-color: transparent;
}
.checkout-promo-code-input-feedback-container{
    padding-left: 10 px;
    opacity: 0%;
    max-height: 0px;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out, padding 0.3s ease-in-out;;
}
.checkout-promo-code-input-feedback-success{
    padding-top: 20px;
}
.checkout-promo-code-input-feedback-error{
    padding-left: 10px;
    width: calc(100% - 10px);
    color: #888;
}
.checkout-promo-code-input-feedback-container.expand{
    opacity: 100%;
    max-height: 1000px;
}
.checkout-promo-code-input-price-tag-container{
    padding-left: 0px;
    opacity: 0%;
    max-width: 0px;
    transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.checkout-promo-code-input-price-tag-container.expand{
    padding-left: 10px;
    opacity: 100%;
    max-width: 100px;
}
