.checkout-wrapper{
    width: 100%;
}
.checkout-loading-container{
    height: 100vh;
    height: 100dvh;
}
.checkout-main-container{
    width:100%;
    min-height: 100vh;
    min-height: 100dvh;
    max-width: 1440px;
    padding-top: 60px;
    box-sizing: border-box;
}
.checkout-back-button-container{
    text-align:left;
    width: 100%;
    padding-top: 20px;
}
@media screen and (min-width: 1120px){
    .checkout-main-container{
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 160px;
        padding-right: 160px;
        margin: 0 auto;
    }
    .checkout-main-background{
        height:100%;
        width:50%;
        position:fixed;
        top:0;
        right:0;
        box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);
    }
    .checkout-details-container{
        padding: 20px 0px 75px 0px;
        display: flex;
        box-sizing: border-box;
        width:100%;
        justify-content: center;
    }
    .checkout-items-overview-wrapper{
        /* padding-right: 80px; */
        /* min-width:380px; */
        width: 50%;
        box-sizing: border-box;
        /* max-height:calc(100vh - 60px - 60px)!important; 60 navbar + 60 backButton */
        /* overflow-y: scroll; */
    }
    .checkout-side-to-side-container{
        /* padding-left: 80px; */
        max-width:380px;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .checkout-payment-wrapper{
        /* padding-left: 80px; */
        /* min-width:380px; */
        position: relative;
        width: 50%;
        box-sizing: border-box;
        /* max-height:calc(100vh - 60px  - 60px)!important; 60 navbar + 60 backButton */
    }
    .checkout-side-sticky-container{
        /* padding-left: 80px; */
        position: sticky;
        max-width:380px;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        top: 80px /* 60px header + 20px padding*/
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .checkout-main-container{
        display: flex;
        box-sizing: border-box;
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 75px;
        /* row-gap: 20px; */
    }
    .checkout-details-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 1px;
        row-gap: 20px;
    }
}
@media screen and (max-width:680px) {
    .checkout-main-container{
        display: flex;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 75px;
        /* row-gap: 20px; */
    }
    .checkout-details-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 1px;
        row-gap: 20px;
    }
}