
.select-product-consumption-type-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.select-product-consumption-type-name-container{
    text-align: center;
    padding: 20px 0px;
}
.select-product-consumption-type-options-wrapper{
    max-height: 0px;
    overflow: hidden;
    transition: ease-in-out 300ms max-height 0ms;
}
.select-product-consumption-type-options-wrapper.show{
    transition: ease-in-out 300ms max-height 300ms;
    max-height: 1000px;
}
