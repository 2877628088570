.payment-method-select-main-container{
    display: flex;
    align-items: center;
    height: 70px;
    padding: 0px 10px;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
    cursor: pointer;
    color: #4d4d4d;
    transition: color .1s ease;
}
.payment-method-select-main-container:hover{
    color:black;
}
.default-payment-method-label{
    font-size: 0.875rem !important;
    font-weight: 600 !important;
}
.default-payment-method-details{
    font-size: 0.875rem !important;
    /* opacity: 0.5 */
}
