.workguide-features-wrapper{
    display: flex;
    justify-content: center;
}
.workguide-features-main-container{
    box-sizing: border-box;
    width:100%;
    max-width: 1440px;
}
.workguide-features-content-container{
    flex-wrap: wrap;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
}
@media screen and (min-width:1120px) {
    .workguide-features-main-container{
        padding: 72px 160px;
    }
    .workguide-features-title-container{
        padding-bottom: 64px;
    }
    .workguide-features-subtitle-container{
        padding-bottom: 48px;
    }
    .workguide-features-content-container{
        row-gap: 32px;
    }
    .workguide-features-content-feature{
        flex-basis: calc((100% - 64px) / 3);
        width: 100%
    }
}
@media screen and (min-width:681px) and (max-width: 1119px) {
    .workguide-features-main-container{
        padding: 72px 32px;
    }
    .workguide-features-title-container{
        padding-bottom: 64px;
    }
    .workguide-features-subtitle-container{
        padding-bottom: 48px;
    }
    .workguide-features-content-container{
        row-gap: 16px;
    }
    .workguide-features-content-feature{
        flex-basis: calc((100% - 32px) / 3);
        width: 100%
    }
}
@media screen and (max-width:680px) {
    .workguide-features-main-container{
        /* padding: 48px 12px; */
        padding: 72px 12px;
    }
    .workguide-features-title-container{
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .workguide-features-subtitle-container{
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .workguide-features-content-container{
        flex-direction: column;
        /* row-gap: 24px; */
        row-gap: 32px;
    }
    .workguide-features-content-feature{
        flex-basis: 100%;
        width: 100%
    }
}
.workguide-features-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}