.video-player-main-container{
}

.video-player-background{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
}
.video-player-main{
    position:absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    opacity: 0%;
    transition: opacity 1s ease-in-out 1s;
} 
.video-player-main.visible{
    opacity: 100%;
}
.video-player-thumbnail{
    pointer-events: all;
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 100%;
    transition: opacity 1s ease-in-out,
}
.video-player-thumbnail-preview-loading{
    width: 100%;
    height: 100%;
}
.video-player-thumbnail.hidden{
    opacity: 0%;
    pointer-events: none;
}
.video-player-error-container{
    position:absolute;
    width: 100%;
    top:0px;
}
