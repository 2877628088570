.reset-password-main-container{
    min-height: 100vh;
    min-height: 100dvh;
    padding-top: 80px;
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
}
.reset-password-modal-container{
    width: 380px;
}
.reset-password-modal-inner-container{
    padding: 20px;
}
.reset-password-message-item{
    padding-top: 20px;
}
.reset-password-input-wrapper{
    padding: 20px;
}
.reset-password-button{
    padding-top: 20px;
}
.reset-password-animation-container{
    width: 100px;
}
.reset-password-error-container{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.reset-password-error-container.show{
    opacity: 1;
    max-height: 50px;
    padding: 20px 0px 0px 20px;
}