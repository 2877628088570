.big-footer-wrapper{
    display: flex;
    justify-content: center;
}
.big-footer-logo-container{
    padding-bottom: 24px;
}
.big-footer-main-container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
}

.big-footer-slogan-container{}

.big-footer-links-column-container{
    row-gap: 16px;
}
.big-footer-conditions-and-legal-container{
    padding-bottom: 8px;
}

@media screen and (min-width:681px) {
    .big-footer-main-container{
        padding:32px;
    }
    .big-footer-slogan-container{
        row-gap: 24px; 
        max-width: 25%;
        flex-basis: 25%;
    }
    .big-footer-links-container{
        justify-content: space-evenly;
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }
    .big-footer-horizontal-divider{
        margin: 32px 0px !important
    }
    @media screen and (min-width:1120px) {
        .big-footer-main-container{
            padding:40px;
        }
        .big-footer-slogan-container{
            min-width: 300px;
            flex-basis: 300px
        }
    }
}
@media screen and (max-width:680px) {
    .big-footer-main-container{
        padding:32px 12px 32px 12px;
    }
    .big-footer-slogan-container{
        row-gap: 16px; 
        max-width: 350px;
        padding-bottom: 32px;
    }
    .big-footer-main-links-container{
        flex-direction: column;
    }
    .big-footer-links-container{
        flex-direction: column;
        row-gap: 32px;
    }
    .big-footer-horizontal-divider{
        margin: 32px 0px !important
    }
    .big-footer-conditions-and-legal-container{
        flex-direction: column;
        row-gap: 16px;
        padding-bottom: 32px;
    }
}