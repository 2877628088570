.content-workguide-toggle-wrapper{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}
.content-workguide-toggle-shrinkable-container{
    max-height: 0px;
    overflow-y: hidden;
    opacity: 0;
    transition: max-height 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}
.content-workguide-toggle-shrinkable-container.toggled{
    max-height: 10000px;
    opacity: 1;
}
.content-workguide-toggle-button-container{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0px auto;
    z-index: 1;
    min-height: 40px;
    padding: 8px 16px;
    box-sizing: border-box;
    width: fit-content;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.content-workguide-toggle-button-label-icon{
}
@media screen and (min-width: 1120px) {
    .content-features-wrapper{
        padding:72px 0px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-features-wrapper{
        padding:72px 0px;
    }
}
@media screen and (max-width: 680px) {
    .content-features-wrapper{
        /* padding:48px 0px; */
        padding:72px 0px;
    }
}