.user-basic-data-menu-main-container{
}
.user-basic-data-menu-main-title{
    padding-bottom: 20px;
}
.user-basic-data-menu-content-container{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.user-basic-data-menu-img-group-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 10px;
    align-items: end;
}
/* .clickable-icon{
    cursor: pointer;
} */
.user-basic-data-menu-img-container{
}
.user-basic-data-menu-img{
    border-radius: 50%;
    overflow: hidden;
    object-fit:cover;
}
.user-basic-data-menu-img-upload-img-widget-container{
    padding: 0px 20px 20px 20px;
}
.user-basic-data-menu-img-group-icon-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 8px;
}
.user-basic-data-menu-name-group-container{
    display: flex;
    box-sizing: border-box;
    row-gap: 20px;
    column-gap: 20px;
}
.user-basic-data-menu-specialization-group-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 10px;
}
.user-basic-data-menu-save-button-container-group{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    row-gap: 10px;
}
@media screen and (min-width: 1120px) {
    
}
@media screen and (min-width: 681px) and (max-width:1119px) {
    .user-basic-data-menu-name-group-container{
        flex-direction: column;
    }
}
@media screen and (max-width: 680px) {
    .user-basic-data-menu-name-group-container{
        flex-direction: column;
    }
}