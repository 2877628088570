.content-slider-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.content-slider-main-container{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.content-slider-right-handler-container{
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    top:0;
    right:0;  
}
.content-slider-left-handler-container{
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    top:0;
    left:0;        
}
@media screen and (min-width: 1120px) {
    .content-slider-main-container{
        padding-bottom: 24px;
    }
    .content-slider-right-handler-container{
        width: 90px;
    }
    .content-slider-left-handler-container{
        width: 80px;
    }
}
@media screen and (max-width: 1119px) and (min-width: 681px){
    .content-slider-main-container{
        padding-bottom: 16px;
    }
    .content-slider-left-handler-container{
        display:none;
    }
    .content-slider-right-handler-container{
        width:16px;
    }
    .content-slider-right-handler-button{
        display: none;
    }
}
@media screen and (max-width: 680px){
    .content-slider-main-container{
        padding-bottom: 8px;
    }
    .content-slider-left-handler-container{
        display:none;
    }
    .content-slider-right-handler-container{
        display: none;
    }
    .content-slider-right-handler-button{
        display: none;
    }
}