.class-congrats-modal-main{
    
}

.class-content-modal-inner{
    padding:30px 20px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    row-gap: 20px;
}

.class-content-modal-animation{
    height: 150px;
}
