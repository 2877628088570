.add-user-note-container{
    position:absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    transition: width ease-in-out 0.3s;
    align-items: start; /* new */
}
.add-user-note-container.right{
    right: 10px;
    align-items: end;
}
.add-user-note-circular-button-container{
    height: fit-content;
    width: fit-content;
    border-radius: 50%;
    /* box-shadow: 1px 1px 10px rgba(182, 182, 182, 0.3); */
    box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
    transition: transform ease-in-out 0.3s, background-color 0.3s ease-in-out !important;
}
.add-user-note-circular-button-icon{
    font-size: 35px !important;
    display: block !important;
    cursor: pointer;
    color: #f3f3f3;
    transition: color ease-in-out 0.3s !important,
}
.add-user-note-editor-wrapper{
    resize: vertical !important;
    justify-content: start;
    padding-bottom: 0px;
    transition: padding-bottom ease-in-out 0.3s;
}
.add-user-note-editor-wrapper.right{
    justify-content: end;
}
.add-user-note-editor-wrapper.open{
    padding-bottom: 15px;
}
.add-user-note-editor{
    background-color: #fff;
    /* box-shadow: 1px 1px 10px rgba(182, 182, 182, 0.3); */
    box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
    overflow: hidden;
    width:0px !important;
    height: fit-content !important;
    max-height: 0px;
    padding: 0px;
    transition: width ease-in-out 0.3s, max-height ease-in-out 0.3s, padding ease-in-out 0.3s;
}
.add-user-note-editor.open{
    width: calc(100%) !important;
    padding: 10px;
    max-height: 500px;
    border: solid 1px #f3f3f3;
    border-radius: 5px;
}
.add-user-note-editor-error-toogle{
    text-align: left;
    max-height: 0px;
    overflow-y: hidden;
    opacity: 0%;
    padding: 0px;
    white-space: pre-line;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.add-user-note-editor-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}