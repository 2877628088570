.clickable-loading-text-container{
    padding-right: 0px;
    overflow: hidden;
    opacity: 0%;
    transition: opacity 0.3s ease-in-out, max-width 0.3s ease-in-out, padding-right 0.3s ease-in-out;
    max-width: 0px;
}
.clickable-loading-text-container.visible{
    padding-right: 10px;
    opacity: 100%;
    max-width: 1000px;
    width:fit-content;
    cursor: pointer;
}
.clickable-loading-text-container.disabled{
    pointer-events: none;
}
.clickable-loading-text-container.visible.disabled{
    opacity: 40%;
}
.clickable-loading-text-container.loading{
    pointer-events: none;
    opacity: 100%;
}
.clickable-loading-text-container.inline{
    display: inline;
    padding: 0px;
}
.clickable-loading-text-container.inline p{
    display: inline;
}