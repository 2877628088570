.pop-up-main{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0px;
    left:0px;
    background: rgba(0, 0, 0, 0.575);
    z-index: 1101;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Compensate the body scroll blocking */
    padding-right: inherit;
    box-sizing: border-box;
    padding:0px
    /*  */
}

@keyframes fade-in {
    from { opacity: 0%;}
    to { opacity: 100%;}
}


.pop-up-main-visible{
    visibility: visible;
    animation: fade-in 0.3s;
}

.pop-up-main-hidden{
    visibility: hidden;
}

.pop-up-modal{
    overflow: hidden;
    overflow-y: auto;
    max-height: 90vh;
    min-height: 100px;
    position:relative;
    height: auto;
    transition: height ease-in-out 0.3s !important;
    scrollbar-width: none; /* Hide scrollbar on Firefox*/
    -ms-overflow-style: none; /* Hide scrollbar on Edge and IE*/
}

.pop-up-modal::-webkit-scrollbar{
    width: 0 !important /* Hide scrollbar on Chrome, Safari and Opera */
}

.pop-up-modal-close-icon-basic{
    z-index: 1101;
    position: absolute;
    cursor: pointer;
}
.pop-up-modal-close-icon{
    fill:black;
    left: 10px;
    top: 10px;
}
