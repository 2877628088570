.header-landing-wrapper{
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    width:100%;
}
.header-landing-main-container{
    width: 100%;
    max-width: 1440px;
    transition: ease-in-out 300ms color, ease-in-out 300ms box-shadow, ease-in-out 300ms background-color;
}
.header-landing-toolbar{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header-landing-logo-container{
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.header-landing-links-container{
    display: flex;
    align-items: center;
}
.header-landing-links-button{
    margin-right: 10px;
}
.header-landing-user-menu-container{
    display: flex;
}
.header-landing-toolbar-buttons {
    margin-left: auto;
}
.header-landing-bar-nav-elem{
    padding:0px 10px;
}
.header-landing-text-link{
    text-decoration: none; 
    color: grey;
}
.header-landing-text-cickable{
    cursor: pointer;
}
.header-landing-hamburguer-paper{
    overflow: visible !important;
    box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    /* filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32)); */
    margin-top: 10px;
    min-width: 200px !important;
    max-width: 100% !important
}
.header-landing-hamburguer-paper:before{
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0
}
@media screen and (min-width: 1120px){
    .header-landing-toolbar{
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px){
    .header-landing-toolbar{
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (max-width: 680px){
    .header-landing-toolbar{
        padding-left: 12px;
        padding-right: 12px;
        position:relative;
        justify-content: space-between;
    }
    .header-landing-hamburguer-menu-container{
        animation: show;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }
    .header-landing-user-menu-container{
        animation: show;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
    }
    .header-landing-logo-container{
        overflow-x: hidden;
        position: absolute;
        max-width: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
    .header-landing-logo-container-animated{
        overflow-x: hidden;
        position: absolute;
        left: 12px;
        /* top: 0px */
        animation: shrink, translation-center;
        animation-delay: 1s, 1.6s;
        animation-duration:0.3s, 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes shrink {
        0% {
            /* clip-path: inset(0px 0px 0px 0px); */
            max-width: 300px;               
        }
        100% {
            /* clip-path: inset(0px 73% 0px 0px);    */
            max-width: 30px;
        }
    }
    @keyframes translation-center {
        0% {
            left: 0;
            max-width: 30px;
            transform: translateX(0%);
        }
        100%{
            left: 50%;
            max-width: 30px;
            transform: translateX(-50%);
        }
    }
    @keyframes show {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 100%;
        }
    }
}