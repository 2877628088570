.class-side-element-main{
    padding: 1em;
    min-height: 2em;
}

.class-side-element-wrapper{
    cursor: pointer;
    height: 100%;
    align-items: center;
}

.class-side-element-icon-wrapper{
    height: 100%;
}

