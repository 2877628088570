.basic-product-consumption-option-item-main-container{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px;
    position: relative;
}
.basic-product-consumption-option-item-main-container.disabled-on-click{
    cursor: auto;
}
.basic-product-consumption-option-item-main-container.disabled{
    cursor: auto;
    opacity: 0.5;
}
.basic-product-consumption-option-item-img-container{
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
}
.basic-product-consumption-option-item-img{
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.basic-product-consumption-option-item-img-skeleton{
    height: 40px !important;
    width: 40px;
    object-fit: cover;
}
.basic-product-consumption-option-item-details-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-grow: 1;
}
.basic-product-consumption-option-item-icons-container{
    /* position: absolute;
    right: 10px;
    top: 5px; */
    display: flex;
    box-sizing: border-box;
    justify-content: end;
    column-gap: 4px;
}
.basic-product-consumption-option-item-purchasability-details-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 4px;
    align-items: center;
}
