.user-profile-badge-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}
.user-profile-badge-main-container{
    display: flex;
    column-gap: 5px;
    box-sizing: border-box;
    align-items: center;
}
.user-profile-badge-img{
    /* width: 100%; */
    /* height: 100%; */
    border-radius: 50%;
    object-fit: cover;
}
