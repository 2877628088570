.landing-hero-message-wrapper{
    width: 100%;
}
.landing-hero-message-main-container{
    box-sizing: border-box;
    max-width: 1440px;
    margin: 0px auto;
    padding-top: 144px;
    padding-bottom: 144px;
    text-align: center;
}
@media screen and (min-width:1120px) {
    .landing-hero-message-main-container{
        padding-left: 160px;
        padding-right: 160px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-hero-message-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (max-width:680px) {
    .landing-hero-message-main-container{
        max-width: 350px;
        padding-left: 12px;
        padding-right: 12px;
    }
}