.banner-action-reminder-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
}
.banner-action-reminder-main-container{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 1440px;
}
.banner-action-reminder-info-container{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}
.banner-action-reminder-description-container{
    flex-grow: 1;
    flex-basis: 0;
}
.banner-action-reminder-avail-unit-container{
    flex-grow: 1;
    flex-basis: 0;
}
.banner-action-reminder-button-container{
    padding-left: 20px;
}
@media screen and (min-width: 1120px) {
    .banner-action-reminder-main-container{
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .banner-action-reminder-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
}
@media screen and (max-width: 680px) {
    .banner-action-reminder-main-container{
        padding-left: 12px;
        padding-right: 12px;
    }
    .banner-action-reminder-fade-in-container{
        animation: fade-in 500ms ease-in;
    }
    .banner-action-reminder-fade-in-container.show{
        display: block;
    }
    .banner-action-reminder-fade-in-container.hide{
        display: none;
    }
}
@keyframes fade-in {
    from {
        opacity: 0%;
    }
    to{
        opacity: 100%;
        
    }
}