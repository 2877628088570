.user-profile-main-container{
    display: flex;
    min-height: 100vh;
    min-height: 100dvh;
    box-sizing: border-box;
    max-width: 1440px;
    padding-top: 80px;
    margin: 0 auto;
}
.user-profile-warning-popup-wrapper{
    padding:20px
}
.user-profile-menu-container{
    /* background-color: #fafafa; */
    /* border-right: solid 1px rgba(0, 0, 0, 0.12); */
    max-width: 300px;
    width: 100%;
}
.user-profile-content-container{
    margin-right: 20px;
    padding: 20px 20px 72px 20px;
    width: 100%;
    box-sizing: border-box;
}
.user-profile-error-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.user-profile-error-animation-container{
    max-width:200px
}
.user-profile-error-animation-container > svg {
    display: block;
}
@media screen and (min-width:1120px) {
    .user-profile-main-container{
    }
    .user-profile-menu-container{
        /* background-color: #fafafa; */
        /* border-right: solid 1px rgba(0, 0, 0, 0.12); */
        max-width: 300px;
        width: 100%;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .user-profile-menu-container{
        max-width: none;
        width: auto;
    }
}
@media screen and (max-width:680px) {
    .user-profile-main-container{
        flex-direction: column;
        min-height: unset;
        padding-top: 60px;
    }
    .user-profile-menu-container{
        max-width: none;
    }
    .user-profile-content-container{
        padding: 20px 20px 72px 20px;
        margin: 0px 10px 0px 10px;
        border-radius: 4px 4px 0px 0px;
        width: auto;
    }
}