.workguide-feature-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    row-gap: 24px;
}
.workguide-feature-img-container{
    width: 150px;
    height: auto;
}
.workguide-feature-title-container{
    width: 100%
}
.workguide-feature-title{
    font-weight: bold !important;
    padding-bottom: 8px;
    text-align: center;
}
.workguide-feature-description{
}
