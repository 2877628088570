.checkout-payment-method-main{
    width:100%;
}
.checkout-payment-method-form{
    opacity:100%;
    transition: opacity ease-in-out 0.5s;
    height: 100%;
}
.checkout-payment-method-hidden{
    visibility: hidden !important;
    opacity: 0%;
    height: 0px;
}
.checkout-payment-method-container{
    max-height: 0px;
    opacity: 0;
    padding-bottom: 0px;
    transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s, padding-bottom ease-in-out 0.3s;
}
.checkout-payment-method-container.show{
    max-height: 1000px;
    opacity: 1;
}
.checkout-payment-method-item{
    padding-bottom: 20px;
} 
.checkout-payment-method-button-container{
    /* padding-top: 20px; */
}
.checkout-payment-method-line{
    border-bottom: solid 1px;
    opacity: 0.1;
    flex-grow: 1;
}
.checkout-payment-method-new-toggle-button-container{
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 50%;
    border-radius: 5px;
    padding: 0px 10px;
}
.checkout-payment-method-new-toggle-button-container:hover{
    opacity: 100%;
    background-color: #fafafa;
}
.checkout-payment-method-new-toggle-button-next-icon{
    height: 0.8em;
    transition: transform ease-in-out 0.3s;
}