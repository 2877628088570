.mailing-list-subscription-is-subscribed-container{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 10px 0px;
    border-radius: 5px;
}
.mailing-list-subscription-is-subscribed-animation{
    /* height: 100%; */
    width: 100px;
}
.mailing-list-subscription-is-subscribed-animation > svg{
    display: block
} 
.mailing-list-subscription-main-container{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.mailing-list-subscription-form-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    padding-right: 24px;
    min-width: 320px;
    box-sizing: border-box;
    position: relative;
}
.mailing-list-subscription-form-input-container{
    opacity: 1;
    transition: ease-in-out 300ms opacity;
}
.mailing-list-subscription-form-input-container.hide{
    visibility: hidden;
    opacity: 0;
}
.mailing-list-subscription-consent-loading-container{
    width: fit-content;
    padding:4px 4px 4px 8px !important;
}
.mailing-list-subscription-consent-checkbox{
    padding:4px 4px 4px 8px !important;
}
.mailing-list-subscription-feedback-container{
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: ease-in-out 300ms opacity;
} 
.mailing-list-subscription-feedback-item-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.mailing-list-subscription-feedback-container.show{
    visibility: visible;
    opacity: 1;
}
.mailing-list-subscription-feedback-animation{
    overflow-y: hidden;
    height: 100%;
    width: 30px;
} 
.mailing-list-subscription-feedback-animation > svg{
    display: block
} 
.mailing-list-subscription-feedback-msg{
    padding-left: 8px;
    white-space: pre-line;
}

.mailing-list-subscription-button-container{
    text-align: center;
    /* width:100% */
}
.mailing-list-subscription-toggle-msg{
    bottom:0px;
    background-color: #fff;
}
@media screen and (max-width:680px) {
    .mailing-list-subscription-form-container{
        padding-bottom: 16px;
        padding-right: 0px;
        min-width: unset;
    }
    .mailing-list-subscription-button-container{
        text-align: center;
        width:100%;
    }
    .mailing-list-subscription-is-subscribed-container{
        /* flex-direction: column; */
    }
}