.banner-promo-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.banner-promo-main-container{
    position:relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    box-sizing: border-box;
    min-height: 60px;
    max-width: 1440px;
    padding-top: 4px;
    padding-bottom: 4px;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
}
.banner-promo-main-container-item{
    box-sizing: border-box;
    /* height: 100%; */
}
.banner-promo-description-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    max-width: 33%;    
}
.banner-promo-drescription-title{
    padding: 4px 0px
}
.banner-promo-promo-container{
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
}
.banner-promo-promo-container-item-value-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.banner-promo-end-container{
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
}
.banner-promo-close-container{
    cursor: pointer;
    border-radius: 50%;
}
.banner-promo-close-container:hover{
    background: rgba(255,255,255,0.1);
}
@media screen and (min-width:1120px) {
    .banner-promo-main-container{
        padding-left: 40px;
        padding-right: 40px;
    }
    .banner-promo-promo-container-item{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
    }
    .banner-promo-promo-container-item-value-container{
        padding: 4px;
        margin: 0 auto;
    }
    .banner-promo-promo-container{
        column-gap: 16px;
        justify-content: center;
    }
    .banner-promo-know-more-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
    }
    .banner-promo-close-container{
        position: absolute;
        right: calc(20px - 12px);
        top: 0;
        bottom: 0;
        margin: auto;
    }    
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .banner-promo-main-container{
        padding-left: 32px;
        padding-right: 32px;
    }
    .banner-promo-promo-container-item{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
    }
    .banner-promo-promo-container-item-value-container{
        padding: 4px;
        margin: 0 auto;
    }
    .banner-promo-promo-container{
        column-gap: 16px;
        justify-content: center;
    }
    .banner-promo-know-more-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
    }
    .banner-promo-close-container{
        position: absolute;
        right: calc(16px - 12px);
        top: 0;
        bottom: 0;
        margin: auto;
    }    
}
@media screen and (max-width:680px) {
    .banner-promo-main-container{
        padding-left: 12px;
        padding-right: 24px;
    }
    .banner-promo-main-container-item{
        flex-grow: 1;
    }
    .banner-promo-discount-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        /* order: 1 */
    }
    .banner-promo-discount-value-container{
        padding: 4px;
        margin: 0 auto;
    }
    .banner-promo-code-container{
        display:flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
    }
    .banner-promo-code-value-container{
        padding: 4px;
        width: fit-content;
        /* margin: 0 auto; */
    }
    .banner-promo-end-container{
        justify-content: center;
    }
    .banner-promo-close-container{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }    
}