.custom-popover-container{
    position: fixed;
    box-shadow: 1px 1px 10px rgba(182, 182, 182, 0.3);
    /* max z-index */
    padding: 10px;
    border-radius: 3px;
    transition: z-index ease-in-out 0.3s, opacity ease-in-out 0.3s;
}
.custom-popover-container-transform{
    position: absolute;
    background-color: black;
    transform: rotate(45deg);
}

.custom-popover-container.show{
    /* max-width: 100vw; */
    opacity: 100%;
    z-index:999999;
}

.custom-popover-container.hide{
    /* max-width: 0px; */
    opacity: 0%;
    z-index: -1;
}