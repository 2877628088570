.checkout-overview-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 20px;
}
.checkout-overview-item{
    padding-top: 10px;
}
.checkout-overview-billing-address{
}
.checkout-overview-product-container{
    /* Padding handle on children containers */
}
.checkout-overview-details{
}
