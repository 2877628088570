.popup-menu{
    overflow: visible !important;
    filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
    margin-top: 10px;
    min-width: 150px!important;
}
.popup-menu:before{
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0
}

.popup-menu-item-icon{
    margin-right: 10px
}

.popup-menu-divider{
    margin-top: 5px ;
    margin-bottom: 5px;
}