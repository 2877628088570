.upload-img-widget-container{
}
.upload-img-widget-crop-wrapper{
    border-radius: 5px;
}
.upload-img-widget-img{
    max-width: 100% !important;
    max-height: 50vh !important;
}
.upload-img-widget-feedback-toggle{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.upload-img-widget-feedback-toggle.show{
    opacity: 1;
    max-height: 50px;
    padding: 0px 0px 20px 20px;
}
