.checkout-items-main-container{
    /* padding-top: 20px; */
    row-gap: 20px;
}
.checkout-items-subscription-items-container{
    row-gap: 10px;
}
.checkout-items-container{
    row-gap: 10px;
}
.checkout-items-animation-container{
    margin:0 auto;
    height: 100px;
}


