.class-content-breadcrumb-truncate-crum{
    flex-shrink: 0;
}
.class-content-breadcrumb-crumb{
    flex-shrink: 1;
}
.class-content-breadcrumb-main-container .MuiBreadcrumbs-ol{
    flex-wrap: nowrap !important;
}
.class-content-breadcrumb-main-container .MuiBreadcrumbs-li{
    min-width: 0px;
}
.class-content-breadcrumb-main-container .MuiBreadcrumbs-li:first-of-type{
    flex-shrink: 0;
}
.class-content-breadcrumb-main-container .MuiBreadcrumbs-li > p{
    min-width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}