.class-content-header-main-container{
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
}
.class-content-header-breadcrumb-wrapper{
    flex-grow: 1;
    padding-left: 10px;
    min-width: 1px
}