.content-professors-main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}
.content-professors-title-container{
    width:100%
}
.content-professors-subtitle-container{
    width:100%
}
.content-professors-lead-professors-container{
    width: 100%;
}
.content-professors-no-lead-subtitle-container{
    width:100%
}
.content-professors-no-lead-professors-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width:100%
}
.content-professors-no-lead-professors-container{
    width:100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 32px;
    margin: 0 auto;
}
@media screen and (min-width:1120px) {
    .content-professors-main-container{
        padding-top: 72px
    }
    .content-professors-title-container{
        padding: 0px 160px;
        box-sizing: border-box;
        padding-bottom: 64px;
    }
    .content-professors-subtitle-container{
        padding: 0px 160px;
        box-sizing: border-box;
        padding-bottom: 48px;
    }
    .content-professors-no-lead-subtitle-container{
        padding: 0px 160px;
        box-sizing: border-box;
        padding-bottom: 48px;
    }
    .content-professors-lead-professors-container{
        /* row gap is already implemented in ProfessorHero as padding */
    }
    .content-professors-lead-content-professor-container{
        position:relative;
        padding: 0px 160px;
        box-sizing: border-box;
    }
    .content-professors-no-lead-professors-wrapper{
        padding: 72px 0px
    }
    .content-professors-no-lead-professors-container{
        padding: 0px 160px;
        box-sizing: border-box;
    }
}
@media screen and (min-width:681px) and (max-width:1119px){
    .content-professors-main-container{
        padding-top: 72px
    }
    .content-professors-title-container{
        padding: 0px 32px;
        box-sizing: border-box;
        padding-bottom: 64px;
    }
    .content-professors-subtitle-container{
        padding: 0px 32px;
        box-sizing: border-box;
        padding-bottom: 48px;
    }
    .content-professors-no-lead-subtitle-container{
        padding: 0px 32px;
        box-sizing: border-box;
        padding-bottom: 48px;
    }
    .content-professors-lead-professors-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 32px 72px 32px;
        /* row-gap: 32px; */
        row-gap: 40px;
    }
    .content-professors-no-lead-professors-wrapper{
        padding: 72px 0px
    }
    .content-professors-no-lead-professors-container{
        padding: 0px 32px;
        box-sizing: border-box;
    }
}
@media screen and (max-width:680px){ 
    .content-professors-main-container{
        /* padding-top: 48px; */
        padding-top: 72px
    }
    .content-professors-title-container{
        padding: 0px 12px;
        box-sizing: border-box;
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .content-professors-subtitle-container{
        padding: 0px 12px;
        box-sizing: border-box;
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .content-professors-no-lead-subtitle-container{
        padding: 0px 12px;
        box-sizing: border-box;
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .content-professors-lead-professors-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /* padding: 0px 12px 48px 12px; */
        padding: 0px 12px 72px 12px;
        /* row-gap: 32px; */
        row-gap: 40px;
    }
    .content-professors-no-lead-professors-wrapper{
        /* padding: 48px 0px */
        padding: 72px 0px
    }
    .content-professors-no-lead-professors-container{
        padding: 0px 12px;
        box-sizing: border-box;
    }
}
.max-width{
    max-width: 1440px;
}
.content-professors-main-container.no-vertical-padding{
    padding-top: 0px;
}