.landing-our-content-wrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    align-items: center;
}

@media screen and (min-width:1120px) {
    .landing-our-content-wrapper{
        padding-top: 72px;
        padding-bottom: 72px;
        row-gap: 56px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-our-content-wrapper{
        padding-top: 72px;
        padding-bottom: 72px;
        row-gap: 56px;
    }
}
@media screen and (max-width:680px) {
    .landing-our-content-wrapper{
        padding-top: 48px;
        padding-bottom: 48px;
        row-gap: 48px;
    }

}
