.content-purchase-perks-wrapper{
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 72px 0px 
}
.content-purchase-perks-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 32px;
}
.content-purchase-perks-risk-free-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 32px;
    padding: 32px;
    border-radius: 10px;
    width: 100%
}
.content-purchase-perks-risk-free-content{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 16px;
    width: 100%
}
.content-purchase-perks-three-perks-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    column-gap: 32px;
}
.content-purchase-perks-perk-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 8px;
    align-items: center;
    padding: 32px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .content-purchase-perks-main-container{
        row-gap: 32px;
    }
    .content-purchase-perks-three-perks-container{
        column-gap: 16px;
    }
}
@media screen and (max-width: 680px) {
    .content-purchase-perks-wrapper{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        /* padding: 48px 0px  */
        padding: 72px 0px 
    }
    .content-purchase-perks-risk-free-container{
        flex-direction: column;
        row-gap: 16px;
        align-items: center;
    }
    .content-purchase-perks-risk-free-content{
        align-items: center;
    }
    .content-purchase-perks-three-perks-container{
        flex-direction: column;
        row-gap: 32px;
        align-items: center;
    }
    .content-purchase-perks-customer-service-badge-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

    }
}