.message-display-main{
    position:fixed;
    z-index: 1111;
    bottom:20px;
    left:20px;
    width:300px
}

.message-display-item{
    padding-top: 10px;
}