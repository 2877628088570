.landing-brands-wrapper{
    display: flex;
    justify-content: center;
}
@media screen and (min-width:1120px) {
    .landing-brands-main-container{
        padding: 32px 160px;
        max-width: 1440px;
    }
    .landing-brands-arrow-container{
        padding-right: 72px;
    }
    .landing-brands-text-container{
        padding-right: 48px;
        white-space: pre-line;
        /* width: 150px; */
    }
    .landing-brands-brands-first-row-container{
        padding-bottom: 16px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px){
    .landing-brands-main-container{
        padding: 72px 32px;
    }
    .landing-brands-arrow-container{
        box-sizing: content-box !important;
        width: 112px;
        padding-right: 16px;
    }
    .landing-brands-arrow{
        margin: 0px auto;
    }
    .landing-brands-text-container{
        padding-bottom: 40px;
    }
    .landing-brands-brands-first-row-container{
        padding-bottom: 40px;
    }
}
@media screen and (max-width:680px){
    .landing-brands-main-container{
        padding: 48px 12px;
    }
    .landing-brands-arrow-container{
        display: none;
    }
    .landing-brands-text-container{
        padding-bottom: 32px;
    }
    .landing-brands-brands-container{
        max-width: 300px;
        margin: 0px auto;
    }
    .landing-brands-brands-first-row-container{
        padding-bottom: 16px;
    }
    .landing-brands-brands-second-row-container{
        padding-bottom: 16px;
    }
    .landing-brands-brands-third-row-container{
        padding-bottom: 16px;
    }
}