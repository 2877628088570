.content-basic-features-wrapper{
    display:flex;
    justify-content: center;
}
.content-basic-features-main-container{
    width: 100%;
    max-width: 1440px;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: center;
}
@media screen and (min-width: 1120px) {
    .content-basic-features-main-container{
        padding: 72px 160px;
    }
    .content-basic-feature-container{
        display:flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 8px;
        border-left: solid 0.5px;
        padding-left: 32px;
        flex-basis: 25%;
    }
    .content-basic-feature-container.first{
        border: none;
        padding-left: 0px;
    }
    .content-basic-feature-value{
        display:flex;
        box-sizing: border-box;
        align-items: flex-start;
        column-gap: 8px;
    }
    .content-basic-feature-value-label{
        flex-grow: 1;
        -webkit-hyphens: auto;
        hyphens: auto;
        word-break:break-word;
        overflow-wrap: break-word;
        /* word-wrap: break-word;  DEPRECATED*/
    }

}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-basic-features-main-container{
        padding: 72px 32px;
    }
    .content-basic-feature-container{
        display:flex;
        flex-direction: column;
        box-sizing: border-box;
        row-gap: 8px;
        border-left: solid 0.5px;
        padding-left: 32px;
        flex-basis: 33%;
    }
    .content-basic-feature-container.first{
        border: none;
        padding-left: 0px;
    }
    .content-basic-feature-value{
        display:flex;
        box-sizing: border-box;
        align-items: flex-start;
        column-gap: 8px;
    }
    .content-basic-feature-value-label{
        flex-grow: 1;
        -webkit-hyphens: auto;
        hyphens: auto;
        word-break:break-word;
        overflow-wrap: break-word;
        /* word-wrap: break-word;  DEPRECATED*/
    }

}
@media screen and (max-width: 680px) {
    .content-basic-features-main-container{
        padding: 72px 12px;
        /* row-gap: 24px; */
        row-gap: 32px;
    }
    .content-basic-feature-container{
        display:flex;
        row-gap: 8px;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        flex-basis: 50%;
    }
    .content-basic-feature-value{
        display:flex;
        box-sizing: border-box;
        align-items: flex-start;
        column-gap: 8px;
    }
    .content-basic-feature-value-label{
        flex-grow: 1;
        -webkit-hyphens: auto;
        hyphens: auto;
        word-break:break-word;
        overflow-wrap: break-word;
        /* word-wrap: break-word;  DEPRECATED*/
    }

}