.customer-service-badge-main{

}

.customer-service-img{
    /* height: 150px; */
    /* width: auto; */
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}