.loading-pop-up-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding: 20px;
}
.loading-pop-up-message-container{

}
.loading-pop-up-loading-container{
    height: 100px;
}