.verify-account-form-wrapper{
    width:100%;
    min-height: 100px;
    align-items: center;
}
.verify-account-form-text-section{
    padding-bottom: 10px;
}
.verify-account-form-input-section{
    padding-bottom: 30px;
}
.verify-account-form-input-container{
    column-gap: 10px;
    align-items: center;
    justify-content: center;
}
.verify-account-form-passive-feedback-container{
    padding-top: 10px;
}
.verify-account-form-passive-feedback-msg{
    text-align: center;
}
.verify-account-form-button-section{
    padding-bottom: 20px;
}
.verify-account-form-input-container-item{
    width: 20%;
    text-align: center;
}
.verify-account-form-button-container{
    align-items: center;
}
.verify-account-form-button{
    padding: 0px 10px
}
.verify-account-form-toggle-msg-container{
    top: 0px;
    width: 100%;
    background-color: #fff;
}
.verify-account-form-refresh-wrapper{
    padding-bottom: 20px;
    text-align: center;
}
.verify-account-form-refresh-link{
    text-decoration: none;
    cursor: pointer;
}
.verify-account-form-refresh-link:hover{
    text-decoration: underline
}
.verify-account-form-refresh-link.disabled{
    opacity: 0.4;
    pointer-events:none;
}
.verify-account-form-request-error-container{
}
.verify-account-form-request-error-animation-container{
    width: 50px;
}
.verify-account-form-request-error-animation{
    height: 50px;
}
.verify-account-form-request-error-msg-container{
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding-left: 10px;
}