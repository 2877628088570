.landing-how-it-works-wrapper{
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    justify-content: center;
}
.landing-how-it-works-main-container{
    box-sizing: border-box;
}
.landing-how-it-works-img-decorator.show{
    opacity: 1;
}
.landing-how-it-works-scroll-guide-wrapper{
    height: 4px;
    padding: 8px 0px;
    width:100%;
}
.landing-how-it-works-scroll-guide{
    height: 100%;
    border-radius: 5px;
}
.landing-how-it-works-img-wrapper.show{
    opacity: 1;
    transition: ease-in-out 150ms opacity 150ms;
}

@media screen and (min-width:1120px) {
    .landing-how-it-works-main-container{
        max-width: 1440px;
        padding: 0px 160px;
        width: 100%;
        /* height: 400vh; */
    }
    .landing-how-it-works-title-and-progress-container{
        position: sticky;
        z-index: 1;
        padding: 80px 0px;
        top: 0px;
    }
    .landing-how-it-works-sections-container{
        display: flex;
        box-sizing: border-box;
        align-items: center;
    }
    .landing-how-it-works-text-column{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /* relatives to the dimension of the element */
        row-gap: 50vh; 
        width: 50%;
    }
    .landing-how-it-works-text-container{
        height: calc(100vh - 160px);
        height: calc(100dvh - 160px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding-right: 80px;
        row-gap: 16px;
    }
    .landing-how-it-works-img-column{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        /* relatives to the dimension of the element */
        row-gap: 50vh; 
        width: 50%;
    }
    .landing-how-it-works-img-wrapper{
        position: sticky;
        top: 160px;
        height: calc(100vh - 160px);
        height: calc(100dvh - 160px);
        width: 100%;
        opacity: 0;
        transition: ease-in-out 150ms opacity 0ms;
    }
    .landing-how-it-works-img-decorator{
        position: absolute;
        object-fit: contain;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: ease-in-out opacity 300ms 500ms;
    }
    .landing-how-it-works-img-container{
        position: relative;
        height: 100%;
        width: 100%;
    }
    .landing-how-it-works-img-component-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .landing-how-it-works-img{
        position: absolute;
        object-fit: contain;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }
}

@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-how-it-works-main-container{
        padding: 0px 32px;
        width: 100%;
    }
    .landing-how-it-works-title-and-progress-container{
        position: sticky;
        z-index: 1;
        padding: 80px 0px;
        top: 0px;
    }
    .landing-how-it-works-sections-container{
        display: flex;
        box-sizing: border-box;
        align-items: center;
    }
    .landing-how-it-works-text-column{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
    }
    .landing-how-it-works-text-container{
        height: calc(100vh - 160px);
        height: calc(100dvh - 160px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding-right: 80px;
        row-gap: 16px;
    }
    .landing-how-it-works-img-column{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 50%;
    }
    .landing-how-it-works-img-wrapper{
        position: sticky;
        top: 160px;
        height: calc(100vh - 160px);
        height: calc(100dvh - 160px);
        width: 100%;
        opacity: 0;
        transition: ease-in-out 150ms opacity 0ms;
    }
    .landing-how-it-works-img-decorator{
        position: absolute;
        object-fit: contain;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: ease-in-out opacity 300ms 500ms;
    }
    .landing-how-it-works-img-container{
        position: relative;
        height: 100%;
        width: 100%;
    }
    .landing-how-it-works-img-component-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .landing-how-it-works-img{
        position: absolute;
        object-fit: contain;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
    }
}
@media screen and (max-height: 600px) {
    .landing-how-it-works-title-and-progress-container{
        position: initial;
        /* padding: 80px 0px 64px 0px; */
        top: unset;
    }
    .landing-how-it-works-sections-container{
        padding-bottom: 80px;
    }
    .landing-how-it-works-text-column{
        row-gap: 80px;
    }
    .landing-how-it-works-img-column{
        row-gap: 80px;
    }
    .landing-how-it-works-img-wrapper{
        z-index: unset;
        position: initial;
        top: unset;
    }
}
@media screen and (max-width: 680px){
    .landing-how-it-works-title-and-progress-container{
        position: sticky;
        z-index: 1;
        padding: 80px 0px;
        top: 0px;
    }
    .landing-how-it-works-main-container{
        padding: 0px 12px;
        width: 100%;
    }
    .landing-how-it-works-sections-container{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        align-items: center;
        /* padding-top: 160px; */
        row-gap: calc((100vh - 160px) / 2);
        row-gap: calc((100dvh - 160px) / 2);
    }
    .landing-how-it-works-section-container{
        height: calc(100vh - 160px);
        height: calc(100dvh - 160px);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        row-gap: 0px;
        width: 100%;
        opacity: 0;
    }
    .landing-how-it-works-section-container.show{
        position: sticky;
        top: 160px;
        opacity: 1;
        transition: ease-in-out 300ms opacity;
    }
    .landing-how-it-works-extra-section{
        height: 50vh;
    }
    .landing-how-it-works-img-wrapper{
        /* position: sticky; */
        /* top: 160px; */
        /* height: calc(100vh - 160px); */
        width: 100%;
        opacity: 0;
        transition: ease-in-out 300ms opacity 0ms;
        /*  */
        display: flex;
        align-items: center;
        box-sizing: border-box;
        flex-grow: 1;
    }
    .landing-how-it-works-img-wrapper.show{
        transition: ease-in-out 300ms opacity 300ms;
    }
    .landing-how-it-works-img-container{
        position: relative;
        width: 100%;
        /*  */
        height: 100%;
        max-height: 200px;
    }
    .landing-how-it-works-img-component-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .landing-how-it-works-img{
        /* position: absolute; */
        object-fit: contain;
        /* top: 0; */
        width: 100%;
        /*  */
        height: 100%;
    }
    .landing-how-it-works-img-decorator{
        position: absolute;
        object-fit: contain;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: ease-in-out opacity 300ms 500ms;
        /*  */
        height: 100%;
    }
    .landing-how-it-works-text-container{
        /* height: calc(100vh - 160px); */
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        row-gap: 16px;
        /*  */
        padding-bottom: 80px;
    }
}