.copy-to-clipboard-main-container{
    height: 100%;
    width: 100%;
}
.copy-to-clipboard-edit{}
.copy-to-clipboard-edit-input{
    border: solid 1px;
    border-radius: 3px;
    /* border-color: red; */
    background: #fafafa;
    /* height:3em; */
    height: inherit;
    width: inherit;
    padding:15px
}
.copy-to-clipboard-edit-input:hover{
}
.copy-to-clipboard-edit-input:focus{
}
.copy-to-clipboard-edit-input-button{
    margin-left: 15px !important;
}
/* .copy-to-clipboard-edit-input-button-icon{
    cursor: pointer;
    padding: 5px;
}
.copy-to-clipboard-edit-input-button-icon:hover{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 100%;
} */