.future-content-section-wrapper{
    display:flex;
    justify-content: center;
    width: 100%;
}
.future-content-section-main-container{
    width: 100%;
    max-width: 1440px;
}
.future-content-section-carrousel-no-slider-inner-container{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
}
@media screen and (min-width:1120px) {
    .future-content-section-main-container{
        padding: 72px 0px;
    }
    .future-content-section-carrousel-container{
        max-width: inherit;
        padding: 0px 80px;
    }
    .future-content-section-title{
        padding: 0 160px 64px 160px;
        text-align: center;
    }
    .future-content-section-subtitle{
        padding: 0 160px 48px 160px
    }

    .future-content-section-carrousel-slider-inner-container{
        height: 100%;
        padding: 0px 80px;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-no-slider-inner-container{
        padding: 0px 80px;
        column-gap: 16px;
        row-gap: 16px;
    }
    .future-content-section-carrousel-no-slider-item{
        flex-grow: 0;
        /* extra pixel to prevent row overflow: 8px => 9px */
        flex-basis: calc(33% - 9px);
        /* max-width: calc(33% - 8px); */
        height: inherit !important;
        width: 100%;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-item{
        padding: 0px 20px 32px 0px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .future-content-section-main-container{
        padding: 72px 0px;
    }
    .future-content-section-carrousel-container{
        max-width: inherit;
        padding: 0px 16px;
    }
    .future-content-section-title{
        padding: 0 32px 64px 32px;
        text-align: center;
    }
    .future-content-section-subtitle{
        padding: 0 32px 48px 32px
    }
    .future-content-section-carrousel-slider-inner-container{
        height: 100%;
        padding: 0px 16px;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-item{
        padding: 0px 20px 32px 0px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-no-slider-inner-container{
        padding: 0px 16px;
        column-gap: 16px;
        row-gap: 16px;
    }
    .future-content-section-carrousel-no-slider-item{
        flex-basis: calc(50% - 10px);
        height: inherit !important;
        width: 100%;
        box-sizing: border-box;
    }
}
@media screen and (max-width:680px) {
    .future-content-section-main-container{
        /* display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden; */
        padding: 72px 0px;
    }
    .future-content-section-carrousel-container{
        max-width: inherit;
        /* padding: 0px 6px; */
    }
    .future-content-section-title{
        padding: 0 12px 64px 12px;
        text-align: center;
    }
    .future-content-section-subtitle{
        padding: 0 12px 48px 12px
    }
    .future-content-section-carrousel-slider-inner-container{
        width: 850px;
        /* this matches space between with previous container */
        /* width: 675px; */
        /* padding: 0px 0px 0px 0px; */
        height: 100%;
        padding: 0px 12px;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-item{
        padding: 0px 12px 24px 0px;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .future-content-section-carrousel-no-slider-inner-container{
        padding: 0px 12px;
        column-gap: 16px;
        row-gap: 16px;
    }
    .future-content-section-carrousel-no-slider-item{
        flex-grow: 1;
        height: inherit !important;
        width: 100%;
        box-sizing: border-box;
    }
    @media screen and (max-width: 430px) {
        .future-content-section-carrousel-slider-inner-container{
            width: unset;
        }
        .future-content-section-carrousel-item{
            padding: 0px 6px 24px 6px;
            height: 100%;
            /* flex-grow: 1; */
            /* max-width: 100%; */
            /* height: auto; */
            width: 100%;
            box-sizing: border-box;
        }
    }
}

.future-content-section-main-container.no-vertical-padding{
    padding-top:0px;
    padding-bottom:0px;
}