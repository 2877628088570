.maximize-device-in-figures-wrapper{
    width: 100%;
    position: relative;
}
.maximize-device-in-figures-main-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}
.maximize-device-in-figures-figure-container{
    flex-basis: 50%;
    text-align: center;
    /* display: flex; */
    /* flex-direction: column; */
    /* flex-wrap: wrap; */
    box-sizing: border-box;
}
.maximize-device-in-figures-figure-value{
}
.maximize-device-in-figures-legend-container{
    /* position: absolute; */
    /* bottom: 0px */
}
@media screen and (min-width:1120px){
    .maximize-device-in-figures-main-container{
        padding: 30px;
    }
}
@media screen and (min-width:681px){
    .maximize-device-in-figures-main-container{
        row-gap: 32px;
    }
}
@media screen and (max-width:680px){
    .maximize-device-in-figures-wrapper{
        max-width: 400px;
    }
    .maximize-device-in-figures-main-container{
        row-gap: 8px;
    }
}