.text-editor-wrapper{
    /* min-height: inherit; */
    width:100%;
    height: 100%; 
    overflow-x: hidden;
}
.text-editor-style-control-wrapper{
    min-height: 40px;
    align-items: center;
    row-gap: 8px;
    padding: 8px 0px
}
.text-editor-style-control-icon-container{
    height: 100%;
    width: 40px;
}
.text-editor-style-control-icon{
    height: 100% !important;
    margin: 0px auto;
    font-size: 24px !important;
    display: block !important;
    cursor: pointer;
}
.text-editor-textarea-wrapper{
    width:100%;
    height:100%;
    hyphens: auto;
    word-break:break-word;
    overflow-wrap: break-word;
    /* word-wrap: break-word;  DEPRECATED*/
}
/* Keep scroll visible when enter is typed and <br/> is included */
.DraftEditor-root {
    overflow-y: inherit;
}
.DraftEditor-editorContainer{
    overflow-y: inherit;
}