.content-description-actions{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    row-gap: 10px;
}

.content-description-actions-item{
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.content-description-actions-content{
    display: flex;
    box-sizing: border-box;
    flex-wrap: nowrap;
    width: 100%;
    align-items:center;
}

.content-description-actions-button{
    cursor: pointer;
    padding-right: 10px;
}