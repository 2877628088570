.user-meeting-date-badge-main-container{
    position: relative;
    height: 120px;
    width: 0px;
    overflow: hidden;
    margin-right: 0px;
    opacity: 0;
    transition: width ease-in-out 0.3s, margin-right ease-in-out 0.3s, opacity ease-in-out 0.3s;    
}
.user-meeting-date-badge-main-container.show{
    width: 120px;
    overflow: initial;
    opacity: 1;
    margin-right: 10px;
}
.user-meeting-date-badge-panelist{
    position: absolute;
    bottom: 0px;
}