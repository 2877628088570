.login-bar-nav-elem{
    padding:0px 10px;
}
.login-text-link{
    text-decoration: none; 
    color: grey;
}

.login-text-cickable{
    cursor: pointer;
}

.login-menu-popover-main{
    padding: 30px;
    width: 280px;
}

.login-menu-popover-item {
    padding-bottom:20px
}

.login-menu-popover{
    overflow: visible !important;
    box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    margin-top: 10px;
    
}
.login-menu-popover:before{
    content: "";
    position: absolute;
    top: 0;
    right: 45px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0
}