/* 
    Reading Lecture styles
    html sanitizing prefixes every id and name attribute with 'user-content'
*/
.markdown-display-error-animation{
    height: 200px;
}

@media screen and (max-width: 680px){
    .markdown-display-error-animation{
        height: 150px;
    }
}
.markdown-display-main-container *:first-child{
    margin-top: 0 !important;
}
.markdown-display-main-container h5{
    margin-block-start: 2em;
    margin-block-end: 0.5em !important;
}
.markdown-display-main-container hr{
    margin-bottom: 2em;
}
.markdown-display-main-container h6{
    margin-block-start: 1em !important;
    margin-block-end: 0.5em !important;
}
.markdown-display-main-container p{
    margin-block-end: 1em !important;
}
.markdown-display-main-container img{
    max-width: 60%;
    margin-top: 2em;
    margin-bottom: 1em;
}
.markdown-display-main-container img[src*="#center"]{
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    float: none!important;
}
.markdown-display-main-container .img-footnote{
    margin-left: auto !important;
    margin-right: auto !important; 
    font-style: italic !important;
    max-width: 60% !important;
    color: #888 !important;
    font-size: 0.8em !important;
    margin-block-end: 2em !important;
}
/* All li except the first one */
.markdown-display-main-container li:not(:nth-child(1)){
    padding-top: 10px;
}
.markdown-display-main-container *:last-child{
    margin-bottom: 0 !important;
}