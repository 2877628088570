.create-user-system-from-selection-form-item{
    padding: 20px;
}
.create-user-system-from-selection-form-error-container{
    max-height: 0px;
}
.create-user-system-from-selection-form-feedback-toggle{
    padding-right: 20px; 
    max-height: 0px;
    opacity: 0%;
    transition: max-heght 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.create-user-system-from-selection-form-feedback-toggle.show{
    padding-left: 20px;
    min-height: 60px;
    max-height: 100px;
    opacity: 100%;
}