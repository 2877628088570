.content-featured-features-wrapper{
    display: flex;
    justify-content: center;
}
.content-featured-features-main-container{
    box-sizing: border-box;
    width:100%;
    max-width: 1440px;
}
.content-featured-features-content-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
}
.content-featured-features-content-img-container{
    border-radius: 5px;
}
@media screen and (min-width:1120px) {
    .content-featured-features-main-container{
        padding: 72px 160px;
    }
    .content-featured-features-title-container{
        padding-bottom: 64px;
    }
    .content-featured-features-subtitle-container{
        padding-bottom: 48px;
    }
    .content-featured-features-content-container{
        column-gap: 64px;
    }
    .content-featured-features-content-description-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-featured-features-content-img-container{
        flex-basis: 50%;
        flex-grow: 1;
        box-sizing: border-box;
        /* min-height: 168px; */
        height: 168px;
        padding: 32px 32px
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .content-featured-features-main-container{
        padding: 72px 32px;
    }
    .content-featured-features-title-container{
        padding-bottom: 64px;
    }
    .content-featured-features-subtitle-container{
        padding-bottom: 48px;
    }
    .content-featured-features-content-container{
        column-gap: 32px;
    }
    .content-featured-features-content-description-container{
        flex-basis: 50%;
        flex-grow: 1;
    }
    .content-featured-features-content-img-container{
        display: flex;
        /* align-items: center; */
        /* max-height: 320px; */
        box-sizing: border-box;
        padding: 32px 32px;
        flex-basis: 50%;
        height: fit-content;
    }
}
@media screen and (max-width:680px) {
    .content-featured-features-main-container{
        /* padding: 48px 12px; */
        padding: 72px 12px;
    }
    .content-featured-features-title-container{
        /* padding-bottom: 40px; */
        padding-bottom: 64px;
    }
    .content-featured-features-subtitle-container{
        /* padding-bottom: 24px; */
        padding-bottom: 48px;
        text-align: center;
    }
    .content-featured-features-content-container{
        flex-direction: column;
        /* row-gap: 24px; */
        row-gap: 32px;
    }
    .content-featured-features-content-description-container{
        flex-basis: 50%;
        order: 1
    }
    .content-featured-features-content-img-container{
        display: flex;
        /* align-items: center; */
        box-sizing: border-box;
        padding: 32px 16px;
        flex-basis: 50%;
        order: 0
    }
}
.content-featured-features-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}