
.value-proposition-item-img-container{
    position: relative;
}
.value-proposition-item-img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.value-proposition-item-img-decorator{
    position: absolute;
    top:0px;
    left:0px;
    right: 0px;
    object-fit: contain;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: ease-in-out opacity 300ms 500ms;
}
.value-proposition-item-img-decorator.show{
    opacity: 1;
}
.value-proposition-item-content-title{
    padding-bottom: 16px;
}
.value-proposition-item-content-description{
    white-space: pre-line;
    padding-bottom: 16px;
}
.value-proposition-item-content-key-point-container{
    padding: 16px;
    align-items: center;
}
.value-proposition-item-content-key-point-icon{
    padding-right: 16px;
}
@media screen and (min-width:1120px) {
    .value-proposition-item-content-container{
        width:50%;
        padding-right: 64px
    }
    .value-proposition-item-content-container.reversed-item{
        width:50%;
        order:2;
        padding-left: 64px;
        padding-right: 0px
    }
    .value-proposition-item-img-container{
        width:50%
    }
}
@media screen and (max-width:1119px) and (min-width:681px) {
    .value-proposition-item-content-container{
        width:50%;
        padding-right: 16px
    }
    .value-proposition-item-content-container.reversed-item{
        width:50%;
        order:2;
        padding-left: 16px;
        padding-right: 0px
    }
    .value-proposition-item-img-container{
        width:50%
    }
}
@media screen and (max-width:680px) {
    .value-proposition-item-content-container{
        order:2;
        width:100%;
    }
    .value-proposition-item-img-container{
        width:100%;
        padding-bottom: 16px
    }
    .value-proposition-item-img{
        height: 200px;
        width: 100%;
        object-fit: contain;
    }
}