.landing-trending-content-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}
.landing-trending-content-no-slider-inner-container{
    display: flex;
    box-sizing: border-box;
    width: 100%;
}

@media screen and (min-width:1120px) {
    .landing-trending-content-main-container{
        padding-top: 72px;
        padding-bottom: 32px;
        max-width: 1440px;
    }
    .landing-trending-content-title{
        padding-left: 160px;
        padding-right: 160px;
        padding-bottom: 48px;
        max-width: inherit;
    }
    .landing-trending-content-slideshow{
        max-width: inherit;
        padding: 0px 80px;
    } 
    .landing-trending-content-no-slider-inner-container{
        padding: 0px 80px;
        column-gap: 16px;
        flex-wrap: nowrap !important;
        /* allowing overflow if no slider is applied */
        /* overflow-x: hidden; */
    } 
    .landing-trending-content-slideshow-inner-container{
        /* width: 1520px; */
        width: 1360px;
        padding: 0px 80px;
        box-sizing: border-box;
    }
    .landing-trending-content-slideshow-item{
        padding-bottom: 24px;
        /* padding-left: 4px; */
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-trending-content-main-container{
        padding-top: 72px;
        padding-bottom: 32px;
    }
    .landing-trending-content-title{
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 48px;
        max-width: inherit;
    }
    .landing-trending-content-slideshow{
        max-width: inherit;
        padding: 0px 16px
    }
    .landing-trending-content-no-slider-inner-container{
        padding: 0px 16px; 
        column-gap: 16px;
        flex-wrap: nowrap !important;
        overflow-y: hidden;
    } 
    .landing-trending-content-slideshow-inner-container{
        /* width: 1048px; */
        width: 936px;
        padding: 0px 16px;
        box-sizing: border-box;
    }
    .landing-trending-content-slideshow-item{
        padding-bottom: 16px;
        padding-left: 4px;
        box-sizing: border-box;
    }
}
@media screen and (max-width:680px) {
    .landing-trending-content-main-container{
        padding-top: 48px;
        padding-bottom: 24px;
    }
    .landing-trending-content-title{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 40px;
        max-width: inherit;
    }
    .landing-trending-content-slideshow{
        max-width: inherit;
        /* updated 25/05 */
        /* padding: 0px 6px */
    }
    .landing-trending-content-slideshow-inner-container{
        width: 624px;
        padding: 0px 12px;
        box-sizing: border-box;
    }
    .landing-trending-content-slideshow-item{
        padding-bottom: 16px;
        padding-left: 4px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 340px) {
        .landing-trending-content-main-container{
            padding: 48px 0px 24px 0px;
        }
        .landing-trending-content-title{
            padding: 0px 12px 40px 12px;
        }
        .landing-trending-content-slideshow{
            padding: 0px
        }
        .landing-trending-content-slideshow-inner-container{
            /* width: inherit; */
            width: 300px;
            padding: 0px;
        }
        .landing-trending-content-slideshow-item{
            padding: 0px 12px 24px 12px;
            /* width: inherit !important */
            width: 100%;
            box-sizing: border-box;
        }
    }
}