.landing-centered-cta-wrapper{
    display: flex;
    justify-content: center;
}
.landing-centered-cta-main-container{
    text-align: center;
}
.landing-centered-cta-subtitle{
    padding-bottom: 24px;
}
@media screen and (min-width:1120px) {
    .landing-centered-cta-main-container{
        padding: 72px 160px;
        flex-basis: 640px;
    }
    .landing-centered-cta-title{
        padding-bottom: 24px;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .landing-centered-cta-main-container{
        padding: 72px 32px;
        flex-basis: 640px;
    }
    .landing-centered-cta-title{
        padding-bottom: 24px;
    }
}
@media screen and (max-width:680px) {
    .landing-centered-cta-main-container{
        padding: 72px 12px;
        flex-basis: 400px;
    }
    .landing-centered-cta-title{
        padding-bottom: 16px;
    }
}
