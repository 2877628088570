.pop-up-create-account-inner{
    padding:40px 20px 0px 20px;
}

.pop-up-create-account-section-title{
    padding: 20px 0px;
}

.pop-up-create-account-hidden-input{
    display: none;
}

.pop-up-create-account-container-animation{
    animation: show 1s;
}
@keyframes show {
    from{
        /* padding-left: 10px; */
        opacity: 0%;
        transform: translateX(-10px);
    }
    to{
        /* padding-left: 0px; */
        opacity: 100%;
        /* transform: translateX(0px); */
    }
}
