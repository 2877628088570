.feature-card-wrapper{
    height: 100%;
}
.feature-card-main-container{
    padding: 16px;
    height: 100%;
}
.feature-card-icon-container{
    padding-bottom: 8px;
}
.feature-card-title-container{
    padding-bottom: 8px;
}