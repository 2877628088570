.basic-config-selector-item-main-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.basic-config-selector-item-details-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.basic-config-selector-item-children-container{
    flex-grow: 1;
}
.basic-config-selector-item-details-selected-icon-container{
    padding-left: 4px;
}
.basic-config-selector-item-icons-container{
    display: flex;
    box-sizing: border-box;
    justify-content: end;
    column-gap: 4px;
}
.basic-config-selector-item-warning-message-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    column-gap: 4px;
}