.create-user-system-form-button-container{
    padding-top: 10px;
    padding-bottom: 20px;
}
.create-user-system-form-button{
    padding-left: 20px;
}
.create-user-system-form-item{
    padding-bottom: 10px;
}
.create-user-system-error-animation-container{
    width: 100px;
}
.create-user-system-error-message-item{
    padding-top: 20px;
}
.create-user-system-form-feedback-toggle{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.create-user-system-form-feedback-toggle.show{
    opacity: 1;
    max-height: 50px;
    padding: 0px 0px 20px 20px;
}