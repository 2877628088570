.checkout-item-main-container{
}
.checkout-item-description-container{
    /* height:40px; */
}
.checkout-item-price-container{
    height:100%;
}
.checkout-item-price-before-promo-code{
    opacity: 50%;
    text-decoration: line-through;
}
.checkout-item-description-text{
    padding-left: 10px;
    height: 100%;
}
.checkout-item-description-img-container{
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
}
.checkout-item-description-img{
    height: 40px;
    width: 40px;
    object-fit: cover;
}
.checkout-item-description-img-skeleton{
    height: 40px !important;
    width: 40px;
    object-fit: cover;
}