.popup-payment-method-edit-dialog-container{
    padding: 20px
}
.popup-payment-method-edit-loading-container{
    height: 200px;
}
.popup-payment-method-edit-feedback-toggle{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.popup-payment-method-edit-feedback-toggle.show{
    opacity: 1;
    max-height: 50px;
    padding: 0px 0px 20px 20px;
}