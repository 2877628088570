.pop-up-meeting-edition-change-unregister-container{
    opacity: 1;
    transform: translateX(0px);
    max-height: 1000px;
    overflow: initial;
    transition: ease-in-out 0.3s opacity, ease-in-out 0.3s transform, ease-in-out 0.3s max-height;
}
.pop-up-meeting-edition-change-unregister-container.hide{
    opacity: 0;
    transform: translateX(-10px);
    max-height: 0px;
    overflow: hidden;
}
.pop-up-meeting-edition-change-register-container{
    opacity: 0;
    transform: translateX(10px);
    max-height: 0px;
    overflow: hidden;
    transition: ease-in-out 0.3s opacity, ease-in-out 0.3s transform, ease-in-out 0.3s max-height;
}
.pop-up-meeting-edition-change-register-container.show{
    opacity: 1;
    transform: translateX(0px);
    max-height: 1000px;
    overflow: initial;
}
