.social-proof-widget-main-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    column-gap: 8px;
}
.social-proof-widget-imgs-container{
    position: relative;
    flex-direction: row-reverse;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}
.social-proof-widget-img{
    float: left;
    margin-left: -8px;
    top: 0px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 3px
}
.content-hero-more-string-pop-over-elem {
    padding: 0 10px;
}
@media screen and (max-width: 680px) {
    .social-proof-widget-main-container{
        flex-direction: column;
        row-gap: 8px;
    }
}