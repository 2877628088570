.user-profile-menu-main{
    padding: 20px 0px;
}

.user-profile-menu-item{
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    opacity: 0.6;
}

.user-profile-menu-item:hover{
    background: #f3f3f3;
    opacity: 1;
}

.user-profile-menu-item-marker{
    padding-left: 0px;
    margin-right: 10px;
    display: inline;
    transition: padding-left ease-in-out 0.3s
}

.user-profile-menu-item-marker-active{
    padding-left: 5px;
}
