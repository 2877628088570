.login-and-verify-form-container-animation{
    animation: show 1s;
}
@keyframes show {
    from{
        /* padding-left: 10px; */
        opacity: 0%;
        transform: translateX(-10px);
    }
    to{
        /* padding-left: 0px; */
        opacity: 100%;
        /* transform: translateX(0px); */
    }
}
