.content-workguide-and-multisystem-main-container{
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 1120px) {
    .content-workguide-and-multisystem-wrapper{
        padding:72px 0px;
    }
    .content-workguide-and-multisystem-title-container{
        padding-bottom: 64px;
    }
    .content-workguide-and-multisystem-main-container{
        row-gap: 64px;
    } 
}
@media screen and (min-width: 681px) and (max-width: 1119px) {
    .content-workguide-and-multisystem-wrapper{
        padding:72px 0px;
    }
    .content-workguide-and-multisystem-title-container{
        padding-bottom: 64px;
    }
    .content-workguide-and-multisystem-main-container{
        row-gap: 64px;
    } 
}
@media screen and (max-width: 680px) {
    .content-workguide-and-multisystem-wrapper{
        /* padding:48px 0px; */
        padding:72px 0px;
    }
    .content-workguide-and-multisystem-title-container{
        /* padding-bottom: 48px; */
        padding-bottom: 64px;
    }
    .content-workguide-and-multisystem-main-container{
        /* row-gap: 48px; */
        row-gap: 64px;
    } 
}
.content-workguide-and-multisystem-main-container.no-vertical-padding{
    padding-top: 0px;
    padding-bottom: 0px;
}