.checkout-billing-address-main-container{
    /* border: 0.5px solid; */
    border-radius: 5px;
    padding: 10px;
}
.checkout-billing-address-location-icon{
    font-size: 40px !important ;
}
.checkout-billing-address-address-container{
    padding-left: 10px;
}
.checkout-billing-address-country{
    padding-left: 10;
}
.checkout-billing-address-skeleton-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}