.popup-meeting-occurrence-register-main-container{
    min-height: inherit;
}
.popup-meeting-occurrence-register-loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: inherit
}
.popup-meeting-occurrence-register-meeting-dialog-container{
    padding: 20px;
}
.popup-meeting-occurrence-register-requesting-error-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.popup-meeting-occurrence-register-requesting-error-animation{
    height: 200px;
}
.popup-meeting-occurrence-register-requesting-error-animation > svg {
    display: block;
}
.popup-meeting-occurrence-register-requesting-error-msg{
    text-align: center;
}
.popup-meeting-occurrence-register-restablishing-request-info{
    padding: 0 20px 20px 20px;
}
