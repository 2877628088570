.language-menu-container{
    cursor: pointer;
    display: flex;
    column-gap: 8px;
}
.language-menu-icon{

}
.language-menu-text{
    
}