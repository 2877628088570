.set-user-system-as-default-dialog-main-container{
}
.set-user-system-as-default-title{
    padding: 20px;
}
.set-user-system-as-default-dialog-selected-system-item{
    padding: 20px
}
.set-user-system-as-default-dialog-buttons-container{
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.set-user-system-as-default-dialog-button-container{
    flex-basis: calc(5 / 12 * 100%);
    flex-grow: 1;
    max-width: calc(5 / 12 * 100%);
}