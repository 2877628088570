.checkout-payment-intent-feedback-wrapper{
    height: 100%;
    width: 100%;
}
.checkout-payment-intent-feedback-loading-container{
    height: 100%;
    width: 100%;
}
.checkout-payment-intent-feedback-main-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 20px;
}
.checkout-payment-intent-feedback-animation-main-container{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    column-gap: 16px;
}
.checkout-payment-intent-feedback-subscription-items-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 8px;
}
.checkout-payment-intent-feedback-subscription-item-container{
    display:flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0px 10px;
    column-gap: 8px;
}
.checkout-payment-intent-feedback-animation-container{
    width:140px
}
.checkout-payment-itent-feedback-subscription-item-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.checkout-payment-intent-feedback-button{
    text-align: center;
}