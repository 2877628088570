.content-slider-dots-wrapper{
    display: flex;
    justify-content: center;
}
.content-slider-dots-dot-wrapper{
    padding: 5px;    
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
    
}
.content-slider-dots-dot{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    transition: width ease-in-out 300ms, background-color ease-in-out 300ms;
}
.content-slider-dots-dot.selected{
    width: 20px;
    border-radius: 20px;
}