.billing-address-form-search-location-input{
    max-height: 200px;
    opacity: 1;
    transition: max-height ease-in-out 0.3s, opacity ease-in-out 0.3s;
}

.billing-address-form-search-location-input.hide{
    max-height: 0px;
    opacity: 0 ; 
    pointer-events: none;
}