.create-account-form-section{
    padding-top: 10px;
    padding-bottom: 10px;
}
.create-account-form-input{
    padding-bottom: 10px;
}
.create-account-form-button{
    padding-top: 10px;
    padding-bottom: 20px;
}
.create-account-form-parallel-inputs{
    width: 45%;
}
.create-account-form-error-container{
    padding: 0px 0px 0px 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    transition: opacity ease-in-out 300ms, padding ease-in-out 300ms, max-height ease-in-out 300ms;
}
.create-account-form-error-container.show{
    opacity: 1;
    max-height: 200px;
    padding: 0px 0px 20px 20px;
}