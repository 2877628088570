.user-profile-img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.user-menu-popover{
    overflow: visible !important;
    /* filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32)); */
    box-shadow: 1px 1px 5px rgba(0,0,0, 0.3) !important;
    margin-top: 10px; 
}
.user-menu-popover:before{
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0
}

.user-menu-popover-item{
    margin-right: 5px
}