.search-location-input{
    z-index: 111111;
}

.pac-container{
    z-index: 10111;
    font-family: 'Noto Sans','Helvetica Neue',Arial,sans-serif !important;
    font-size: 0.8em;
}
/* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
/* .pac-container::after{ 
    background-image: none !important;
    height: 0px;
}  */
.pac-item-query{
    font-size: 1em;
    font-family: 'Noto Sans','Helvetica Neue',Arial,sans-serif !important;
}
.pac-item{
    padding: 5px 10px;
    font-size: 1em;
    font-family: 'Noto Sans','Helvetica Neue',Arial,sans-serif !important;
}
.pac-icon{
    display:none
}