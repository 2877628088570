.checkout-total-wrapper{
    padding: 10px 10px;
    row-gap: 4px;
}
.checkout-total-main-container{
    align-items: center;
    justify-content: space-between;
    /* box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%); */
}
.checkout-total-promo-widget-main-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}