.user-meeting-date-time-item{
    padding-right: 10px;
}
.user-meeting-status-widget-container{
    padding: 0px 5px;
}
.user-meeting-info-container{
    justify-content: center;
    align-items: center;
}
.user-meeting-info-loading-item-wrapper{
    padding: 0px 10px;
}
.user-meeting-low-section-container{
    padding-top: 10px;
    align-items: center
}
.user-meeting-button-container{
    align-items: center;
    justify-content: end;
    
}
.user-meeting-button{
}
.user-meeting-different-meeting-info-wrapper{
    max-height: 0px;
    overflow: hidden;
    padding: 0px 5px;
    transition: ease-in-out 300ms max-height, ease-in-out 300ms padding-top, ease-in-out 300ms padding-bottom;
}
.user-meeting-different-meeting-info-wrapper.show{
    max-height: 1000px;
    padding: 5px;
}
.user-meeting-different-meeting-expand-icon{
    transition: ease-in-out 300ms transform !important;
    display: block;
}
.user-meeting-different-meeting-expand-icon.less{
    transform: rotate(180deg);
}
.user-meeting-different-meeting-explanation-container{
    padding: 0px 10px;
    white-space: pre-line;
}
.user-meeting-error-toogle{
    text-align: left;
    max-height: 0px;
    opacity: 0%;
    padding: 0px;
    white-space: pre-line;
    transition: max-height 0.3s ease-in-out,opacity 0.3s ease-in-out, padding-left 0.3s ease-in-out;
}
.user-meeting-error-toogle.show {
    max-height: 1000px;
    opacity: 100%;
    padding-left: 10px;
}