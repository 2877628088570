.meeting-widget-wrapper{
    box-sizing: border-box;
    position: relative;
    border-radius: 5px;
}
.meeting-widget-ready-main-container{
    box-sizing: border-box;
    width: 100%;
}
.meeting-widget-ready-status-and-refresh-container{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    width:100%
}
.meeting-widget-ready-content-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
}
.meeting-widget-refresh-button-container{
    flex-grow: 1;
    text-align: end;
}
.meeting-widget-refresh-icon-button{
}
.meeting-widget-badge{
}
.meeting-widget-description-container{
    justify-content: center;
    height: 100%;
}
.meeting-widget-user-meeting-status-widget-container{
}
.meeting-widget-user-meeting-container{
    padding-top:5px
}
.meeting-widget-error-container{
    height: 120px;
}
.meeting-widget-error-animation{
    height: auto;
    width: 100px;
    padding-right: 20px;
}
.meeting-widget-error-msg{
    white-space: pre-line;
}

@media screen and (min-width:1120px) {
    .meeting-widget-wrapper{
        width: 80%;
        padding: 20px;
    }
}

@media screen and (max-width:1119px) {
    .meeting-widget-wrapper{
        width: 100%;
        padding: 10px
    }
}