.meeting-granted-repetition-floating-badge-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    left:0px;
    border-radius: 5px;
}
.meeting-granted-repetition-floating-badge-container{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* z-index: 1; */
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}
.meeting-granted-repetition-floating-badge-button{
    padding-top: 20px;
}
.meeting-granted-repetition-floating-badge-error-container{
    padding-right: 20px;
    opacity: 0;
    transition: opacity ease-in-out 300ms, padding-right ease-in-out 300ms;
}
.meeting-granted-repetition-floating-badge-error-container.show{
    opacity: 1;
    padding-right: 0px;
}