.checkout-in-subcription-main-container{
    height: 22px;
    margin-bottom: 5px;
}
.checkout-in-subcription-img-container{
    width:50px;
    height: 100%;
}
.checkout-in-subscription-img{
    border-radius: 5px;
}
.checkout-in-subscription-description{
    padding-left: 10px;
}
.checkout-in-subscription-price{
    padding-right: 10px;
}