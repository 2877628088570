.resource-page-error-main-container{
    height: 100%;
    justify-content: center;
    align-items: center;
}
.resource-page-error-container{
    max-width: 300px;
    justify-content: center;
    align-items: center;
}
.resource-page-error-title{
    font-size: xx-large !important;
    font-weight: bold !important;
    line-height: 2 !important;
}
.resource-page-error-button-container{
    padding-top: 40px;    
}

.resource-page-error-text{
    white-space: pre-line;
    text-align: center;
}