.class-content-main{
    /* padding: 20px 20px; */
    height: 100%;
}
.class-content-alert{
    position:sticky;
    top: 0px
}
@media screen and (max-width:1119px) {
    .class-content-alert{
        top: 60px /* 60px Header */
    }
}
