.user-password-menu-main-container{
}
.user-password-menu-main-title{
    padding-bottom: 20px;
}
.user-password-menu-content-container{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 20px;
}
.user-password-menu-password-group-container{
    display: flex;
    box-sizing: border-box;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
}
.user-password-menu-button-container{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 10px;
}
@media screen and (min-width:1120px) {
    .user-password-menu-password-group-item-container{
        flex-basis: calc(50% - 10px);
        box-sizing: border-box;
    }
}
@media screen and (min-width:681px) and (max-width:1119px) {
    .user-password-menu-password-group-item-container{
        flex-basis: 100%;
        box-sizing: border-box;
    }
}
@media screen and (max-width:680px) {
    .user-password-menu-password-group-item-container{
        flex-basis: 100%;
        box-sizing: border-box;
    }
}