.user-system-select-main{
}
.user-system-select-form{
    min-height: 150px;
}
.user-system-select-form-item-default{
    font-style: italic;
    display: inline;
}
.user-system-select-form-button{
    padding-top: 20px;
}