
.faq-item-answer-main-container{
}
.faq-item-question-container{
    cursor: pointer;
    padding: 40px 0px;
    justify-content: space-between;
    align-items: center;
}
.faq-item-question-icon-container{
    padding-left: 10px;
}
.faq-item-question-icon{
    transition: transform 300ms ease-in-out;
}
.faq-item-answer-container{
    align-items: center;
    padding: 0px;
    max-height: 0px;
    overflow: hidden;
    /* transform: translate3d(0px, -10px, 0px); */
    transition: max-height 300ms ease-in-out, padding 300ms ease-in-out;
}
.faq-item-answer-container.show{
    /* transform: translate3d(0px, 0px, 0px); */
    max-height: 1000px;
    padding-top: 40px;
    padding-bottom: 40px;
}
.faq-item-answer-vertical-line{
    width: 4px;
    height: 100%;
}
.faq-item-answer-text{
    padding-left: 8px;
}
