.log-out-previous-session-modal-main-container{
    align-items: center;
    min-height: 100px;
}
.log-out-previous-session-modal-text{
    padding-bottom: 20px;
    text-align: center;
    white-space: pre-line
}
.log-out-previous-session-badge-container{
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;  
}
.log-out-previous-session-modal-button-container{
    justify-content: center;
    padding-bottom: 20px;
    column-gap: 20px;
}