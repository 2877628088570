.checkout-subscription-item-main-container{
}

.checkout-subscription-item-subcontainer{
    border: 0.5px solid;
    border-radius: 5px;
}

.checkout-subscription-item-container{
    padding: 10px;
}

.checkout-subscription-subscribed-items-container{
    padding-bottom: 5px;
}

.checkout-subscription-item-switch-container{
    opacity: 100%;
    border-top: 0.5px solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation-name: open;
    animation-duration: 0.3s;
}
@keyframes open {
    from {
        max-height: 0px;
        opacity: 0%;
    }
    to{
        max-height: 1000px;
        opacity: 100%;
        
    }
}
.checkout-subscription-item-switch-subcontainer{
    height: 100%;
    align-items: first baseline;
    padding: 0px 10px;
    column-gap: 5px;
}
.checkout-subscription-item-switch-container-description-container{
    padding: 5px 0px;
}
.checkout-subscription-item-switch-alternative-diff{
    padding: 0px 5px;
    border-radius: 5px;
}
.checkout-subscription-item-error-toggle-container{
    padding-bottom: 0px !important;
}