.content-consumption-option-wrapper{
    box-sizing: border-box;
    width: 100%
}
.content-consumption-option-is-best-container{
    text-align: center;
    padding-bottom: 8px;
}
.content-consumption-option-main-container{
    position:relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 32px;
    row-gap: 8px;
    border-radius: 5px;
}
.content-consumption-option-ribbon-container{
    position: absolute;
    top: -5px;
    right: 16px;
    filter: drop-shadow(1px 2px 2px rgba(0,0,0,0.32));
}
.content-consumption-option-price-and-features-container{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    padding: 8px 0px;
}
.content-consumption-option-avail-unit-container{
    padding-bottom: 8px;
}
.content-consumption-option-feature{
    text-align: center;
    white-space: pre-wrap;
}